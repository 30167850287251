export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        // case "zh": //中文
        //     lang = "zh";
        //     break;
        case "tc": //中文
            lang = "tc";
            break;
        case "en": //英文
            lang = "en";
            break;
        case "in": //日语
            lang = "in";
            break;
        case "ko": //韩语
            lang = "ko";
            break;
        case "pt": //葡萄牙语
            lang = "pt";
            break;
        case "es": //西班牙语
            lang = "es";
            break;
        case "th": //泰语
            lang = "th";
            break;
        case "vn": //越南
            lang = "vn";
            break;
        case "id": //印尼
            lang = "id";
            break;
        case "my": //马来
            lang = "my";
            break;
        case "ja": //马来
            lang = "ja";
            break;
        case "de": //马来
            lang = "de";
            break;
        case "fr": //马来
            lang = "fr";
            break;
        case "tr": //马来
            lang = "tr";
            break;
        default:
            lang = "en";
            break;
    }
    return lang;
}
