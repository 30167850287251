global.directionType = [
  { v: 0, name: "买入" },
  { v: 1, name: "卖出" },
]
global.directionTypeMap = _.keyBy(global.directionType, 'v')

global.stateType = [
  { v: 2, name: "web.trade.state4" },
  { v: 3, name: "web.trade.state5" },
]
global.stateTypeMap = _.keyBy(global.stateType, 'v')

global.tabType = [
  { v: 0, name: "全部" },
  { v: 1, name: "外汇" },
  { v: 2, name: "商品" },
  { v: 3, name: "指数" },
  { v: 4, name: "股票" },
  { v: 5, name: "加密 " },
]
global.tabTypeMap = _.keyBy(global.tabType, 'v')

global.city1 = [
  { v: 0, id: 1, name: "中国" },
  { v: 1, id: 2, name: "香港" },
  { v: 2, id: 3, name: "俄罗斯" },
  { v: 3, id: 4, name: "印度" },
  { v: 4, id: 5, name: "印度尼西亚" },
  { v: 5, id: 6, name: "新加坡" },
  { v: 6, id: 7, name: "日本" },
  { v: 7, id: 8, name: "韩国" },
]
global.city1Map = _.keyBy(global.city1, 'v')

global.city2 = [
  { v: 0, id: 9, name: "丹麦" },
  { v: 1, id: 10, name: "冰岛" },
  { v: 2, id: 11, name: "匈牙利" },
  { v: 3, id: 12, name: "奥地利" },
  { v: 4, id: 13, name: "希腊" },
  { v: 5, id: 14, name: "德国" },
  { v: 6, id: 15, name: "意大利" },
  { v: 7, id: 16, name: "挪威" },
  { v: 8, id: 17, name: "捷克共和国" },
  { v: 9, id: 18, name: "斯洛伐克" },
  { v: 10, id: 19, name: "比利时" },
  { v: 11, id: 21, name: "法国" },
  { v: 12, id: 22, name: "波兰" },
  { v: 13, id: 23, name: "爱尔兰" },
  { v: 14, id: 24, name: "瑞典" },
  { v: 15, id: 25, name: "瑞士" },
  { v: 16, id: 26, name: "罗马尼亚" },
  { v: 17, id: 27, name: "芬兰" },
  { v: 18, id: 28, name: "罗马尼亚" },
  { v: 19, id: 29, name: "芬兰" },
  { v: 20, id: 30, name: "英国" },
  { v: 21, id: 31, name: "葡萄牙" },
  { v: 22, id: 32, name: "西班牙" },
]
global.city2Map = _.keyBy(global.city2, 'v')

global.city3 = [
  { v: 0, id: 33, name: "加拿大" },
  { v: 1, id: 34, name: "墨西哥" },
  { v: 2, id: 35, name: "美国" },
]
global.city3Map = _.keyBy(global.city3, 'v')

global.city4 = [
  { v: 0, id: 36, name: "南非" },
  { v: 1, id: 37, name: "哥伦比亚" },
  { v: 2, id: 38, name: "巴西" },
  { v: 3, id: 39, name: "新西兰" },
  { v: 4, id: 40, name: "智利" },
  { v: 5, id: 41, name: "欧洲货币联盟" },
  { v: 6, id: 42, name: "澳大利亚" },
  { v: 7, id: 43, name: "阿根廷" },
]
global.city3Map = _.keyBy(global.city4, 'v')

global.city5 = [
  { v: 0, id: 44, name: "假期" },
  { v: 1, id: 45, name: "经济数据" },
]
global.city3Map = _.keyBy(global.city5, 'v')

global.languageTotal = [
  // { id: 0, abbr: 'zh', name: "简体中文", },
  { id: 1, abbr: 'tc', name: "繁体中文", },
  { id: 2, abbr: 'en', name: "English", },
  { id: 3, abbr: 'pt', name: "Português", },
  { id: 4, abbr: 'ko', name: "한국어", },
  { id: 5, abbr: 'in', name: "हिन्दी", },
  { id: 6, abbr: 'es', name: "Español", },
  { id: 7, abbr: 'th', name: "ไทย", },
  { id: 8, abbr: 'vn', name: "Tiếng Việt", },
  { id: 9, abbr: 'id', name: "Bahasa Indonesia", },
  { id: 10, abbr: 'my', name: "Bahasa Melayu", },
  { id: 11, abbr: 'ja', name: "日本語", },
  { id: 12, abbr: 'de', name: "Deutsch", },
  { id: 13, abbr: 'it', name: "Italia", },
  { id: 14, abbr: 'lm', name: "România", },
  { id: 15, abbr: 'fr', name: "Français", },
  { id: 16, abbr: 'tr', name: "Türkiye", },
]

global.cityType = [
  { v: 0, title: '阿尔巴尼亚', name: "city.albania", code: '355', pic: require('/static/flags/Albania.png') },
  { v: 1, title: '阿尔及利亚', name: "city.algeria", code: '213', pic: require('/static/flags/Algeria.png') },
  { v: 2, title: '阿根廷', name: "city.argentina", code: '54', pic: require('/static/flags/Argentina.png') },
  { v: 3, title: '亚美尼亚', name: "city.armenia", code: '374', pic: require('/static/flags/Armenia.png') },
  { v: 4, title: '澳大利亚', name: "city.australia", code: '61', pic: require('/static/flags/Australia.png') },
  { v: 5, title: '巴基斯坦', name: "city.pakistan", code: '92', pic: require('/static/flags/Pakistan.png') },
  { v: 6, title: '奥地利', name: "city.austria", code: '43', pic: require('/static/flags/Austria.png') },
  { v: 7, title: '巴林', name: "city.bahrain", code: '973', pic: require('/static/flags/Bahrain.png') },
  { v: 8, title: '比利时', name: "city.belgium", code: '32', pic: require('/static/flags/Belgium.png') },
  { v: 9, title: '波黑', name: "city.bosnia_and_Herzegovina", code: '387', pic: require('/static/flags/BosniaandHerzegovina.png') },
  { v: 10, title: '巴西', name: "city.brazil", code: '55', pic: require('/static/flags/Brazil.png') },
  { v: 11, title: '文莱', name: "city.brunei", code: '673', pic: require('/static/flags/Brunei.png') },
  { v: 12, title: '保加利亚', name: "city.bulgaria", code: '359', pic: require('/static/flags/Bulgaria.png') },
  { v: 13, title: '柬埔寨', name: "city.cambodia", code: '855', pic: require('/static/flags/Cambodia.png') },
  { v: 14, title: '加拿大', name: "city.canada", code: '1', pic: require('/static/flags/Canada.png') },
  { v: 15, title: '喀麦隆', name: "city.cameroon", code: '237', pic: require('/static/flags/Cameroon.png') },
  { v: 16, title: '智利', name: "city.chile", code: '56', pic: require('/static/flags/Chile.png') },
  { v: 17, title: '哥伦比亚', name: "city.colombia", code: '57', pic: require('/static/flags/Colombia.png') },
  { v: 18, title: '哥斯达黎加', name: "city.costa_Rica", code: '506', pic: require('/static/flags/Costa Rica.png') },
  { v: 19, title: '克罗地亚', name: "city.croatia", code: '385', pic: require('/static/flags/Croatia.png') },
  { v: 20, title: '塞浦路斯', name: "city.cyprus", code: '357', pic: require('/static/flags/Cyprus.png') },
  { v: 21, title: '捷克', name: "city.czech_Republic", code: '420', pic: require('/static/flags/Czech Republic.png') },
  { v: 22, title: '丹麦', name: "city.denmark", code: '45', pic: require('/static/flags/Denmark.png') },
  { v: 23, title: '多米尼加共和国', name: "city.dominican_Republic", code: '1809', pic: require('/static/flags/Dominican Republic.png') },
  { v: 24, title: '埃及', name: "city.egypt", code: '20', pic: require('/static/flags/Egypt.png') },
  { v: 25, title: '爱沙尼亚', name: "city.estonia", code: '372', pic: require('/static/flags/Estonia.png') },
  { v: 26, title: '埃塞俄比亚', name: "city.ethiopia", code: '251', pic: require('/static/flags/Ethiopia.png') },
  { v: 27, title: '芬兰', name: "city.finland", code: '358', pic: require('/static/flags/Finland.png') },
  { v: 28, title: '法国', name: "city.france", code: '33', pic: require('/static/flags/France.png') },
  { v: 29, title: '格鲁吉亚', name: "city.georgia", code: '995', pic: require('/static/flags/Georgia.png') },
  { v: 30, title: '德国', name: "city.germany", code: '49', pic: require('/static/flags/Germany.png') },
  { v: 31, title: '加纳', name: "city.ghana", code: '233', pic: require('/static/flags/Ghana.png') },
  { v: 32, title: '希腊', name: "city.greece", code: '30', pic: require('/static/flags/Greece.png') },
  { v: 33, title: '圭亚那', name: "city.guyana", code: '592', pic: require('/static/flags/Guyana.png') },
  { v: 34, title: '洪都拉斯', name: "city.honduras", code: '504', pic: require('/static/flags/Honduras.png') },
  { v: 35, title: '中国香港', name: "city.hong_Kong_China", code: '852', pic: require('/static/flags/Hong Kong(China).png') },
  { v: 36, title: '匈牙利', name: "city.hungary", code: '36', pic: require('/static/flags/Hungary.png') },
  { v: 37, title: '冰岛', name: "city.iceland", code: '354', pic: require('/static/flags/Iceland.png') },
  { v: 38, title: '爱尔兰', name: "city.ireland", code: '353', pic: require('/static/flags/Ireland.png') },
  { v: 39, title: '意大利', name: "city.italy", code: '39', pic: require('/static/flags/Italy.png') },
  { v: 40, title: '印度', name: "city.india", code: '91', pic: require('/static/flags/India.png') },
  { v: 41, title: '印度尼西亚', name: "city.indonesia", code: '62', pic: require('/static/flags/Indonesia.png') },
  { v: 42, title: '以色列', name: "city.israel", code: '972', pic: require('/static/flags/Israel.png') },
  { v: 43, title: '伊朗', name: "city.iran", code: '98', pic: require('/static/flags/Iran.png') },
  { v: 44, title: '伊拉克', name: "city.iraq", code: '964', pic: require('/static/flags/Iraq.png') },
  { v: 45, title: '日本', name: "city.japan", code: '81', pic: require('/static/flags/Japan.png') },
  { v: 46, title: '哈萨克斯坦', name: "city.kazakstan", code: '7', pic: require('/static/flags/Kazakstan.png') },
  { v: 47, title: '肯尼亚', name: "city.kenya", code: '254', pic: require('/static/flags/Kenya.png') },
  { v: 48, title: '韩国', name: "city.korea", code: '82', pic: require('/static/flags/Korea.png') },
  { v: 49, title: '科威特', name: "city.kuwait", code: '965', pic: require('/static/flags/Kuwait.png') },
  { v: 50, title: '吉尔吉斯斯坦', name: "city.kyrgyzstan", code: '996', pic: require('/static/flags/Kyrgyzstan.png') },
  { v: 51, title: '老挝', name: "city.laos", code: '856', pic: require('/static/flags/Laos.png') },
  { v: 52, title: '拉脱维亚', name: "city.latvia", code: '371', pic: require('/static/flags/Latvia.png') },
  { v: 53, title: '立陶宛', name: "city.lithuania", code: '370', pic: require('/static/flags/Lithuania.png') },
  { v: 54, title: '卢森堡', name: "city.luxembourg", code: '352', pic: require('/static/flags/Luxembourg.png') },
  { v: 55, title: '中国澳门', name: "city.macao_China", code: '853', pic: require('/static/flags/Macao(China).png') },
  { v: 56, title: '马其顿', name: "city.macedonia", code: '389', pic: require('/static/flags/Macedonia.png') },
  { v: 57, title: '马来西亚', name: "city.malaysia", code: '60', pic: require('/static/flags/Malaysia.png') },
  { v: 58, title: '马耳他', name: "city.malta", code: '356', pic: require('/static/flags/Malta.png') },
  { v: 59, title: '墨西哥', name: "city.mexico", code: '52', pic: require('/static/flags/Mexico.png') },
  { v: 60, title: '摩尔多瓦', name: "city.moldova", code: '373', pic: require('/static/flags/Moldova.png') },
  { v: 61, title: '摩纳哥', name: "city.monaco", code: '377', pic: require('/static/flags/Monaco.png') },
  { v: 62, title: '蒙古', name: "city.mongolia", code: '976', pic: require('/static/flags/Mongolia.png') },
  { v: 63, title: '黑山', name: "city.montenegro", code: '382', pic: require('/static/flags/Montenegro.png') },
  { v: 64, title: '摩洛哥', name: "city.morocco", code: '212', pic: require('/static/flags/Morocco.png') },
  { v: 65, title: '缅甸', name: "city.myanmar", code: '95', pic: require('/static/flags/Myanmar.png') },
  { v: 66, title: '荷兰', name: "city.netherlands", code: '31', pic: require('/static/flags/Netherlands.png') },
  { v: 67, title: '新西兰', name: "city.new_Zealand", code: '64', pic: require('/static/flags/New Zealand.png') },
  { v: 68, title: '尼泊尔', name: "city.nepal", code: '977', pic: require('/static/flags/Nepal.png') },
  { v: 69, title: '尼日利亚', name: "city.nigeria", code: '234', pic: require('/static/flags/Nigeria.png') },
  { v: 70, title: '挪威', name: "city.norway", code: '47', pic: require('/static/flags/Norway.png') },
  { v: 71, title: '阿曼', name: "city.oman", code: '968', pic: require('/static/flags/Oman.png') },
  { v: 72, title: '巴勒斯坦', name: "city.palestine", code: '970', pic: require('/static/flags/Palestine.png') },
  { v: 73, title: '巴拿马', name: "city.panama", code: '507', pic: require('/static/flags/Panama.png') },
  { v: 74, title: '巴拉圭', name: "city.paraguay", code: '595', pic: require('/static/flags/Paraguay.png') },
  { v: 75, title: '秘鲁', name: "city.peru", code: '51', pic: require('/static/flags/Peru.png') },
  { v: 76, title: '菲律宾', name: "city.philippines", code: '63', pic: require('/static/flags/Philippines.png') },
  { v: 77, title: '波兰', name: "city.poland", code: '48', pic: require('/static/flags/Poland.png') },
  { v: 78, title: '葡萄牙', name: "city.portugal", code: '351', pic: require('/static/flags/Portugal.png') },
  { v: 79, title: '波多黎各', name: "city.puerto_Rico", code: '1', pic: require('/static/flags/Puerto Rico.png') },
  { v: 80, title: '卡塔尔', name: "city.qatar", code: '974', pic: require('/static/flags/Qatar.png') },
  { v: 81, title: '罗马尼亚', name: "city.romania", code: '40', pic: require('/static/flags/Romania.png') },
  { v: 82, title: '俄罗斯', name: "city.russia", code: '7', pic: require('/static/flags/Russia.png') },
  { v: 83, title: '特立尼达和多巴哥', name: "city.republic_of_Trinidad_and_Tobago", code: '868', pic: require('/static/flags/Republic of Trinidad and Tobago.png') },
  { v: 84, title: '卢旺达', name: "city.rwanda", code: '250', pic: require('/static/flags/Rwanda.png') },
  { v: 85, title: '沙特阿拉伯', name: "city.saudi_Arabia", code: '966', pic: require('/static/flags/Saudi Arabia.png') },
  { v: 86, title: '塞尔维亚', name: "city.serbia", code: '381', pic: require('/static/flags/Serbia.png') },
  { v: 87, title: '新加坡', name: "city.singapore", code: '65', pic: require('/static/flags/Singapore.png') },
  { v: 88, title: '斯洛伐克', name: "city.slovakia", code: '421', pic: require('/static/flags/Slovakia.png') },
  { v: 89, title: '斯洛文尼亚', name: "city.slovenia", code: '386', pic: require('/static/flags/Slovenia.png') },
  { v: 90, title: '南非', name: "city.south_Africa", code: '27', pic: require('/static/flags/South Africa.png') },
  { v: 91, title: '西班牙', name: "city.spain", code: '34', pic: require('/static/flags/Spain.png') },
  { v: 92, title: '斯里兰卡', name: "city.sri_Lanka", code: '94', pic: require('/static/flags/Sri Lanka.png') },
  { v: 93, title: '瑞典', name: "city.sweden", code: '46', pic: require('/static/flags/Sweden.png') },
  { v: 94, title: '瑞士', name: "city.switzerland", code: '41', pic: require('/static/flags/Switzerland.png') },
  { v: 95, title: '中国台湾', name: "city.taiwan_China", code: '886', pic: require('/static/flags/Taiwan(China).png') },
  { v: 96, title: '塔吉克斯坦', name: "city.tajikistan", code: '992', pic: require('/static/flags/Tajikstan.png') },
  { v: 97, title: '坦桑尼亚', name: "city.tanzania", code: '255', pic: require('/static/flags/Tanzania.png') },
  { v: 98, title: '泰国', name: "city.thailand", code: '66', pic: require('/static/flags/Thailand.png') },
  { v: 99, title: '土耳其', name: "city.turkey", code: '90', pic: require('/static/flags/Turkey.png') },
  { v: 100, title: '土库曼斯坦', name: "city.turkmenistan", code: '993', pic: require('/static/flags/Turkmenistan.png') },
  { v: 101, title: '乌克兰', name: "city.ukraine", code: '380', pic: require('/static/flags/Ukraine.png') },
  { v: 102, title: '阿联酋', name: "city.united_Arab_Emirates", code: '971', pic: require('/static/flags/unitedEmirates.png') },
  { v: 103, title: '英国', name: "city.united_Kingdom", code: '44', pic: require('/static/flags/United Kingdom.png') },
  { v: 104, title: '美国', name: "city.united_States", code: '1', pic: require('/static/flags/United States.png') },
  { v: 105, title: '乌兹别克斯坦', name: "city.uzbekistan", code: '998', pic: require('/static/flags/Uzbekistan.png') },
  { v: 106, title: '委内瑞拉', name: "city.venezuela", code: '58', pic: require('/static/flags/Venezuela.png') },
  { v: 107, title: '越南', name: "city.vietnam", code: '84', pic: require('/static/flags/Vietnam.png') },
  { v: 108, title: '阿富汗', name: "city.afghanistan", code: '93', pic: require('/static/flags/Afghanistan.jpg') },
  { v: 109, title: '安哥拉', name: "city.angola", code: '244', pic: require('/static/flags/Angola.png') },
  { v: 110, title: '阿塞拜疆', name: "city.azerbaijan", code: '994', pic: require('/static/flags/Azerbaijan.jpg') },
  { v: 111, title: '孟加拉国', name: "city.bangladesh", code: '880', pic: require('/static/flags/Bangladesh.jpg') },
  { v: 112, title: '白俄罗斯', name: "city.belarus", code: '375', pic: require('/static/flags/Belarus.jpg') },
  { v: 113, title: '伯利兹', name: "city.belize", code: '501', pic: require('/static/flags/Belize.jpg') },
  { v: 114, title: '贝宁', name: "city.benin", code: '229', pic: require('/static/flags/Benin.png') },
  { v: 115, title: '不丹', name: "city.bhutan", code: '975', pic: require('/static/flags/Bhutan.png') },
  { v: 116, title: '玻利维亚', name: "city.bolivia", code: '591', pic: require('/static/flags/Bolivia.png') },
  { v: 117, title: '博茨瓦纳', name: "city.botswana", code: '267', pic: require('/static/flags/Botswana.png') },
  { v: 118, title: '英属维京群岛', name: "city.british_Virgin_Islands", code: '1809', pic: require('/static/flags/britishVirginIslands.jpg') },
  { v: 119, title: '布基纳法索', name: "city.burkina_Faso", code: '226', pic: require('/static/flags/Burkina Faso.png') },
  { v: 120, title: '布隆迪', name: "city.burundi", code: '257', pic: require('/static/flags/Burundi.png') },
  { v: 121, title: '佛得角', name: "city.cape_Verde", code: '238', pic: require('/static/flags/Cape Verde.jpg') },
  { v: 122, title: '开曼群岛', name: "city.cayman_Islands", code: '001345', pic: require('/static/flags/Cayman Islands.jpg') },
  { v: 123, title: '中非共和国', name: "city.central_African_Republic", code: '236', pic: require('/static/flags/Central African Republic.jpg') },
  { v: 124, title: '乍得', name: "city.chad", code: '235', pic: require('/static/flags/Chad.png') },
  { v: 125, title: '科摩罗', name: "city.comoros", code: '269', pic: require('/static/flags/Comoros.png') },
  { v: 126, title: '刚果（布）', name: "city.the_Republic_of_the_Congo", code: '00242', pic: require('/static/flags/The Republic of the Congo.png') },
  { v: 127, title: '刚果（金）', name: "city.democratic_Republic_of_the_Congo", code: '243', pic: require('/static/flags/Democratic Republic of the Congo.png') },
  { v: 128, title: '吉布提', name: "city.djibouti", code: '253', pic: require('/static/flags/Djibouti.png') },
  { v: 129, title: '厄瓜多尔', name: "city.ecuador", code: '593', pic: require('/static/flags/Ecuador.png') },
  { v: 130, title: '萨尔瓦多', name: "city.el_Salvador", code: '503', pic: require('/static/flags/El Salvador.jpg') },
  { v: 131, title: '赤道几内亚', name: "city.equatorial_Guinea", code: '240', pic: require('/static/flags/Equatorial Guinea.jpg') },
  { v: 132, title: '厄立特里亚', name: "city.eritrea", code: '291', pic: require('/static/flags/Eritrea.jpg') },
  { v: 133, title: '斐济', name: "city.fiji", code: '00679', pic: require('/static/flags/Fiji.jpg') },
  { v: 134, title: '加蓬', name: "city.gabon", code: '241', pic: require('/static/flags/Gabon.png') },
  { v: 135, title: '冈比亚', name: "city.gambia", code: '220', pic: require('/static/flags/Gambia.png') },
  { v: 136, title: '格陵兰', name: "city.greenland", code: '299', pic: require('/static/flags/Greenland.jpg') },
  { v: 137, title: '危地马拉', name: "city.guatemala", code: '502', pic: require('/static/flags/Guatemala.png') },
  { v: 138, title: '几内亚', name: "city.guinea", code: '00224', pic: require('/static/flags/Guinea.png') },
  { v: 139, title: '海地', name: "city.haiti", code: '509', pic: require('/static/flags/Haiti.png') },
  { v: 140, title: '马恩岛', name: "city.isle_of_Man", code: '44', pic: require('/static/flags/Isle of Man.png') },
  { v: 141, title: '科特迪瓦', name: "city.cote_d_Ivoire", code: '00225', pic: require('/static/flags/CotedIvoire.png') },
  { v: 142, title: '牙买加', name: "city.jamaica", code: '876', pic: require('/static/flags/Jamaica.png') },
  { v: 143, title: '约旦', name: "city.jordan", code: '962', pic: require('/static/flags/Jordan.png') },
  { v: 144, title: '黎巴嫩', name: "city.lebanon", code: '961', pic: require('/static/flags/Lebanon.png') },
  { v: 145, title: '莱索托', name: "city.lesotho", code: '266', pic: require('/static/flags/Lesotho.png') },
  { v: 146, title: '利比里亚', name: "city.liberia", code: '231', pic: require('/static/flags/Liberia.png') },
  { v: 147, title: '利比亚', name: "city.libya", code: '218', pic: require('/static/flags/Libya.png') },
  { v: 148, title: '马达加斯加', name: "city.madagascar", code: '261', pic: require('/static/flags/Madagascar.png') },
  { v: 149, title: '马拉维', name: "city.malawi", code: '265', pic: require('/static/flags/Malawi.png') },
  { v: 150, title: '马尔代夫', name: "city.maldives", code: '960', pic: require('/static/flags/Maldives.png') },
  { v: 151, title: '马里', name: "city.mali", code: '223', pic: require('/static/flags/Mali.png') },
  { v: 152, title: '毛里塔尼亚', name: "city.mauritania", code: '222', pic: require('/static/flags/Mauritania.png') },
  { v: 153, title: '毛里求斯', name: "city.mauritius", code: '230', pic: require('/static/flags/Mauritius.png') },
  { v: 154, title: '莫桑比克', name: "city.mozambique", code: '258', pic: require('/static/flags/Mozambique.png') },
  { v: 155, title: '纳米比亚', name: "city.namibia", code: '264', pic: require('/static/flags/Namibia.png') },
  { v: 156, title: '尼加拉瓜', name: "city.nicaragua", code: '505', pic: require('/static/flags/Nicaragua.png') },
  { v: 157, title: '尼日尔', name: "city.republic_of_Niger", code: '227', pic: require('/static/flags/Republic of Niger.png') },
  { v: 158, title: '朝鲜', name: "city.north_Korea", code: '850', pic: require('/static/flags/Korea.jpg') },
  { v: 159, title: '留尼汪', name: "city.reunion", code: '262', pic: require('/static/flags/Reunion.jpg') },
  { v: 160, title: '圣马力诺', name: "city.san_Marino", code: '378', pic: require('/static/flags/San Marino.png') },
  { v: 161, title: '塞内加尔', name: "city.senegal", code: '221', pic: require('/static/flags/Senegal.png') },
  { v: 162, title: '塞拉利昂', name: "city.sierra_Leone", code: '232', pic: require('/static/flags/Sierra Leone.png') },
  { v: 163, title: '索马里', name: "city.somalia", code: '00252', pic: require('/static/flags/Somalia.png') },
  { v: 164, title: '苏丹', name: "city.sudan", code: '249', pic: require('/static/flags/Sudan.png') },
  { v: 165, title: '苏里南', name: "city.suriname", code: '597', pic: require('/static/flags/Suriname.png') },
  { v: 166, title: '斯威士兰', name: "city.eswatini", code: '268', pic: require('/static/flags/Eswatini.png') },
  { v: 167, title: '叙利亚', name: "city.syria", code: '963', pic: require('/static/flags/Syria.png') },
  { v: 168, title: '多哥', name: "city.togo", code: '228', pic: require('/static/flags/Togo.png') },
  { v: 169, title: '汤加', name: "city.tonga", code: '676', pic: require('/static/flags/Tonga.png') },
  { v: 170, title: '突尼斯', name: "city.tunisia", code: '216', pic: require('/static/flags/Tunisia.png') },
  { v: 171, title: '美属维尔京群岛', name: "city.united_States_Virgin_Islands", code: '1-340', pic: require('/static/flags/United_States_Virgin_Islands.png') },
  { v: 172, title: '乌干达', name: "city.uganda", code: '256', pic: require('/static/flags/Uganda.png') },
  { v: 173, title: '乌拉圭', name: "city.uruguay", code: '598', pic: require('/static/flags/Uruguay.png') },
  { v: 174, title: '梵蒂冈', name: "city.vatican", code: '379', pic: require('/static/flags/Vatican.png') },
  { v: 175, title: '也门', name: "city.yemen", code: '967', pic: require('/static/flags/Yemen.jpg') },
  { v: 176, title: '南斯拉夫', name: "city.yugoslavia", code: '381', pic: require('/static/flags/Yugoslavia.png') },
  { v: 177, title: '赞比亚', name: "city.zambia", code: '260', pic: require('/static/flags/Zambia.png') },
  { v: 178, title: '津巴布韦', name: "city.zimbabwe", code: '263', pic: require('/static/flags/Zimbabwe.png') },
  { v: 179, title: '中国', name: "city.china", code: '86', pic: require('/static/flags/China.jpg') },
]
global.cityTypeMap = _.keyBy(global.cityType, 'v')

global.billType = [
  { v: 101, name: '充值' },
  { v: 102, name: '添加' },
  { v: 103, name: '冻结' },
  { v: 104, name: '解冻' },
  { v: 201, name: '提现冻结' },
  { v: 202, name: '扣除' },
  { v: 203, name: '提现成功' },
  { v: 204, name: '提现失败' },
  { v: 205, name: '提现手续费费' },
  { v: 301, name: '保证金扣除' },
  { v: 302, name: '手续费扣除' },
  { v: 303, name: '保证金返还' },
  { v: 304, name: '盈利' },
  { v: 305, name: '亏损' },
]
global.billTypeMap = _.keyBy(global.billType, 'v')

global.walletType = [
  { v: 0, name: 'TRC20' },
  { v: 1, name: 'ERC20' },
  { v: 2, name: 'ETH' },
  { v: 3, name: 'BTC' },
]
global.walletTypeMap = _.keyBy(global.walletType, 'v')
