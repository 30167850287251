export default {
    common: {
        learn: {
            title1: "Saldo primario",
            content1:
                "StratTrade offre gratuitamente il nostro servizio di saldo in contanti e criptovalute Hosted, permettendoti di memorizzare il tuo denaro e la criptovaluta supportata senza alcun costo. Puoi trasferire crypto dal saldo primario di un utente StratTrade al saldo primario di un altro utente senza alcun addebito.\n" +
                "\n" +
                "Commissioni sulle transazioni di rete\n" +
                "\n" +
                "Per le transazioni su reti di criptovaluta (ad esempio, trasferimenti di criptovaluta fuori dalla piattaforma StratTrade), StratTrade incorre e paga commissioni sulle transazioni di rete (ad esempio, commissioni miner). Quando invii criptovaluta dal tuo portafoglio StratTrade a un altro portafoglio, addebiteremo una commissione basata sulla nostra stima delle commissioni di rete prevalenti per un invio stand alone wallet-to-wallet. Tuttavia, la commissione finale che StratTrade paga può differire dalla commissione stimata a causa di fattori quali transazioni di batching o cambiamenti nei livelli di congestione della rete. Tutte le commissioni sono comunicate al momento della transazione.\n" +
                "\n" +
                "Aumento dell'efficienza e tariffe di rete\n" +
                "\n" +
                "A causa di potenziali guadagni di efficienza derivanti dalle transazioni di batching, l'importo complessivo delle commissioni di rete stimate pagate dagli utenti all'interno di un determinato batch può superare la commissione di rete finale pagata da StratTrade.\n" +
                "\n" +
                "Tassa di elaborazione della rete Lightning\n" +
                "\n" +
                "Lightning Network è una rete di micropagamenti che consente il trasferimento di bitcoin senza incorrere nella commissione di transazione associata alla blockchain di Bitcoin. Quando invii bitcoin da StratTrade tramite Lightning Network, addebiteremo una commissione di elaborazione pari allo 0,1% dell'importo di bitcoin trasferito.\n" +
                "\n" +
                "Aggiungere contanti e incassare\n" +
                "\n" +
                "Quando aggiungi contanti o contanti, potrebbe essere addebitata una commissione a seconda del metodo di pagamento selezionato. È importante rivedere i dettagli della commissione prima di completare la transazione per comprendere eventuali addebiti associati.\n" +
                "\n" +
                "Operazioni di credito\n" +
                "\n" +
                "Se presti in prestito USD da StratTrade o da un affiliato di StratTrade e dobbiamo vendere la tua garanzia BTC (come autorizzato in base a un contratto di prestito applicabile), addebiteremo una commissione forfettaria del 2% della transazione totale.",
            title2: "Commissioni di negoziazione e spread",
            content2:
                "Commissioni StratTrade\n" +
                "\n" +
                "Quando acquisti, vendi o converti criptovalute su StratTrade, vengono addebitate commissioni. Queste commissioni vengono calcolate al momento dell'ordine e possono essere influenzate da fattori quali il metodo di pagamento scelto, la dimensione dell'ordine, le condizioni di mercato, la posizione giurisdizionale e altri costi sostenuti per facilitare la transazione.\n" +
                "\n" +
                "Prima di inviare la transazione, è possibile visualizzare le commissioni nella schermata di anteprima commerciale. Si prega di notare che le commissioni possono variare per transazioni simili. Completando un ordine, accetti di pagare le commissioni StratTrade applicabili per tale transazione.\n" +
                "\n" +
                "Spread\n" +
                "\n" +
                "Quando effettui semplici ordini di acquisto e vendita, StratTrade include uno spread nel prezzo quotato. Lo spread è incluso anche nel tasso di cambio quando si converte da una criptovaluta a un'altra.\n" +
                "\n" +
                "Per visualizzare lo spread prima di visualizzare in anteprima un ordine di acquisto, vendita o conversione, nella schermata di anteprima:\n" +
                "\n" +
                "Ordini di acquisto e vendita: tocca il tooltip accanto alla linea di prezzo del tuo asset per visualizzare lo spread.\n" +
                "\n" +
                "Converti ordini: tocca il tooltip accanto al tuo tasso di cambio quotato.\n" +
                "\n" +
                "Lo spread aiuta ad aumentare la probabilità di una transazione riuscita e consente a StratTrade di bloccare temporaneamente il prezzo quotato durante l'elaborazione dell'ordine. StratTrade può trattenere qualsiasi spread in eccesso derivante da una transazione. Si prega di notare che lo spread può variare per transazioni simili.\n" +
                "\n" +
                "StratTrade Advanced\n" +
                "\n" +
                "Nessun spread è incluso per StratTrade Advanced perché stai interagendo direttamente con il libro ordini.\n" +
                "\n" +
                "StratTrade One\n" +
                "\n" +
                "StratTrade One è un prodotto in abbonamento che offre l'acquisto e la vendita gratuiti di criptovalute, con determinate limitazioni. I membri possono ancora avere lo spread incluso nei loro prezzi quotati.\n" +
                "\n" +
                "Modifiche delle commissioni di negoziazione e dello spread\n" +
                "\n" +
                "StratTrade testa occasionalmente le modifiche alle commissioni e allo spread. Queste modifiche possono essere implementate in diverse regioni, attività, dimensioni degli ordini e tipi di operazioni.\n" +
                "\n" +
                "StratTrade ti mostra l'importo totale, comprensivo di spread e commissioni, ogni volta che visualizzi un'anteprima di un trade e successivamente nella cronologia delle transazioni. \n" +
                "\n" +
                "Esecuzione commerciale\n" +
                "\n" +
                "Tutte le operazioni del tuo saldo primario vengono eseguite tramite il portafoglio ordini limite centrale di StratTrade Exchange o aste competitive. Puoi visualizzare il portafoglio ordini di un mercato, fare offerte, chiedere e approfondire in tempo reale. Questi libri di ordini determinano i prezzi quotati che vedi durante il trading.\n" +
                "\n" +
                "StratTrade non ha alcun pagamento per i rapporti di flusso degli ordini (PFOF) con i market maker.",
            title3: "StratTrade Card",
            content3:
                "La spesa con la StratTrade Card non comporta commissioni di transazione. \n" +
                "\n" +
                "StratTrade include uno spread nel prezzo per acquistare o vendere criptovalute. Questo ci consente di bloccare temporaneamente un prezzo per l'esecuzione delle transazioni mentre si esaminano i dettagli della transazione prima di inviare la transazione.  \n" +
                "\n" +
                "Potrebbe essere addebitato un costo da un operatore ATM. Si prega di fare riferimento al proprio accordo StratTrade Cardholder per ulteriori dettagli.",
            title4: "Servizi di palio",
            content4:
                "Non c'è alcuna tassa da puntare o togliere. StratTrade prende una commissione basata sui premi che ricevi dalla rete. La nostra commissione standard è del 35% per ADA, ATOM, DOT, MATIC, SOL e XTZ. Per ADA, ATOM, DOT, SOL e XTZ, la commissione è del 26,3% per i membri StratTrade One idonei. Puoi trovare il tasso di commissione ETH in Dettagli patrimoniali. Alcuni utenti possono ricevere offerte per promozioni di staking con tariffe più basse. L'APY e i premi mostrati nel tuo account riflettono sempre i guadagni che ricevi dopo la nostra commissione. Puoi trovare maggiori informazioni sulle commissioni nel Contratto utente StratTrade.",
            title5: "Recupero di attività",
            content5:
                "Se hai inviato una criptovaluta non supportata al tuo account StratTrade, potrebbe essere idonea per il recupero. Faremo pagare una tassa di rete per il tentativo di recupero. Per i recuperi con un valore stimato di oltre $ 100, addebiteremo anche una commissione di recupero del 5% sull'importo superiore a $ 100. Il valore stimato della ripresa può differire dal valore di mercato effettivo della ripresa.",
        },
        award1: "Il miglior contratto asiatico per Difference Broker nel 2023",
        award2: "2022 Global Best Broker",
        award3: "Broker più fidato nel 2022",
        award4: "Il marchio più affidabile del 2021",
        award5: "Miglior broker di tecnologia finanziaria",
        award6: "Il broker più trasparente per le transazioni",
        analysis: "Analisi",
        health: "Health",
        country: "Paese",
        information: "Informazioni",
        help: "Aiuto",
        confirm: "Riconosci",
        cancel: "Abolire",
        tip: "prompts",
        place_select: "Seleziona",
        placeEnter: "Inserisci",
        countryCode: "Codice paese",
        submit: "Invia ",
        more: "Di più",
        deposit: "Deposito",
        real_trade: "Real Deal",
        paper_trade: "Demo Trading",
        build_paperTrade: "Crea un account demo",
        start_trade: "Inizia a fare trading ora",
        app_name: "StratTrade",
        success: "Successi！",
        dataSources_name: "Trading Central",
        reset: "Riprovisione",
        iknow: "Ho capito!",
        noData: "Nessun dato disponibile",
        modify: "Modifica",
        pingcang: "Chiudi una posizione",
        all: "Pieno",
        tip_cardMain: "Scatta o carica una foto del FRONT della tua carta d'identità",
        tip_cardBack: "Scatta o carica una foto del retro della tua carta d'identità",
        tip_cardMain1:
            "Scattare o caricare una foto della PRIMA della patente di guida",
        tip_cardBack1:
            "Scattare o caricare una foto del POSTO della patente di guida",
        tip_cardMain2: "Scatta o carica una foto del tuo passaporto",
        tip_cardBack2: "Scatta o carica una foto del tuo passaporto",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Eteri",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (coin)",
        adausdt: "Ada currency",
        eosusdt: "Pomelo Coin",
        dotusdt: "Boca coin",
        trxusdt: "Wave field coin",
        xmrusdt: "Monroe Coin",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Canadian and New Zealand dollars",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Petrolio greggio Brent",
        hf_GC: "Oro di New York",
        hf_SI: "Argento di New York",
        hf_HG: "Copper (US)",
        hf_NG: "U.S. Natural Gas",
        hf_CAD: "Copper (chemistry)",
        hf_AHD: "Alluminio Londra",
        HX_AAPL: "Pomegranate",
        HX_BABA: "Alibaba, società di commercio elettronico della RPC",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (rivenditore)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Germany",
        HX_IBEX: "IBEX35, Spain",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "New Zealand 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Futuri su platino",
        hf_XPD: "Futuri Palladio",
        hf_CT: "Cotone USA",
        hf_SM: "Farina di soia americana",
        hf_BO: "Olio di soia americano",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "European Snooker 50",
        HX_ICEXI: "ICEX, Iceland",
        HX_ASE: "ASE Athens, Greece",
        HX_OMXC20: "OMX Copenhagen 20",
        HX_OSEBX: "OSEBX, Norway",
        HX_OMXSPI: "OMXSPI, Sweden",
        HX_AMD: "Chaowei Semiconductor",
        HX_DIS: "Disney (name)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (brand)",
        HX_NVDA: "NVIDIA, computer graphics card company",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Seagate Technology",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Seleziona una valuta",
        currenctType6: "USDT-ERC20",
        currenctType7: "USDT-TRC20",
        currenctType8: "BTC",
        currenctType13: "ETH",
        currenctType14: "Dollar",
        currenctType15: "Hong Kong currency",
        currenctType16: "Taiwan currency",
        currenctType17: "Ringgit",
        currenctType18: "Singapore dollar",
        currenctType19: "Yen",
        currenctType20: "Euro",
        currenctType21: "Dollar A",
        currenctType22: "Rupiah",
        currenctType23: "Pound",
        currenctType24: "Thai baht",
        currenctType25: "Dollar",
        currenctType26: "VND",
        currenctType27: "Korean won",
        currenctType28: "MOP",
    },
    addnew2: {
        address: {
            account_type: "Tipo di conto",
            ifsc_code: "CODICE IFSC",
            title: "Indirizzo del portafoglio",
            account_number: "Numero di conto",
            add_btn: "Aggiungi indirizzo",
            label_type: "Seleziona tipo",
            place_type: "Seleziona il tipo",
            label_number: "Conto",
            place_number: "Inserisci il numero di conto",
            wallet_address: "Indirizzo del portafoglio",
            bank_name: "NOME DELLA BANCA",
            bank_address: "Indirizzo bancario",
            name: "Nome",
            payee_address: "Indirizzo del beneficiario",
            swift_code: "Codice rapido/rapido/BIC",
            aba: "ABA",
            branch_number: "Nome del ramo",
            branch_code: "CODICE BRANCH",
            bank_code: "Codice bancario",
            phone: "Numero di cellulare",
            iban: "IBAN",
            sort_code: "Codice di ordinamento",
            place_inner: "Inserisci il contenuto",
        },
    },
    addnew: {
        paypassTit: "Cambia la password di prelievo",
        paypassTit1: "Si prega di impostare la password di prelievo",
        paypassTit2: "Inizializzare la password con la password di accesso.",
        loan: "Prestito",
        repaid: "Rimborso",
        unpaid: "Non pagato",
        loanMore: "Ho bisogno di un prestito.",
        immediateRepayment: "Rimborso immediato",
        accountType1: "Conto crittografico",
        accountType2: "Conto contrattuale",
        accountType3: "Conti opzioni",
        from: "Obbedire ",
        to: "To",
        get: "Will get",
        labelBreed: "Valuta",
        placeBreed: "Seleziona la valuta",
        labelTransAccount: "Conto",
        labelAmount: "Importo",
        placeAmount: "Inserisci l'importo del bonifico",
        transferTit: "Trasferimento",
        convertTit: "Converti",
        balanceTit: "Saldo stimato",
        available: "Disponibile",
        pending: "Chiudere con un fermo",
        equivalent: "Essere equivalente a",
        coinTrade: "Crypto Trading",
        secondContract: "Secondo contratto",
        number: "Quantitativi",
        labelNumber: "Quantitativi",
        placeNumber: "Inserisci il numero di riscatti",
        time: "Tempistica",
        loadAmount: "Importo previsto del prestito",
        repaymentCycle: "Ciclo di rimborso dei prestiti",
        dailyRate: "Tariffa giornaliera",
        repaymentMethod: "Metodo di rimborso",
        loanTip: "Credito di credito (si prega di assicurarsi che l'immagine sia chiaramente visibile)",
        loanTip1: "Carica certificato di proprietà",
        loanTip2: "Prova del reddito (occupazione)",
        loanTip3: "Dettagli degli estratti conto bancari",
        loanTip4: "Carica la foto frontale della tua carta d'identità",
        interest: "Interesse (desiderio conoscere sth)",
        repaymentMethod1: "Rimborso forfettario",
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Commodities",
        product_number: "Indici",
        product_stock: "Stock",
        product_coin: "Cripto",
        billTypes: [
            { v: 101, name: "Ricarica" },
            { v: 102, name: "Deposito" },
            { v: 103, name: "Congelati" },
            { v: 104, name: "Sblocca" },
            { v: 105, name: "Deposito" },
            { v: 106, name: "Deposito" },
            { v: 201, name: "Congelamento di prelievo" },
            { v: 202, name: "Prelevare fondi" },
            { v: 203, name: "Ritiro riuscito" },
            { v: 204, name: "Mancato prelievo di fondi" },
            { v: 205, name: "Tassa di prelievo" },
            { v: 206, name: "Trasferimento fuori" },
            { v: 207, name: "Sposta a" },
            { v: 208, name: "Trasferimento di monete" },
            { v: 209, name: "Trasferimento di monete" },
            { v: 301, name: "Tassa contrattuale" },
            { v: 302, name: "Redditi contrattuali" },
            { v: 303, name: "Perdita contrattuale" },
            { v: 304, name: "Margine contrattuale" },
            { v: 305, name: "Rendimento del margine contrattuale" },
            { v: 311, name: "Opzioni di acquisto" },
            { v: 312, name: "Redditi di opzioni" },
            { v: 313, name: "Restituisce opzione" },
            { v: 314, name: "Tassa di gestione delle opzioni" },
            { v: 315, name: "Coin Buy Freeze" },
            { v: 316, name: "Detrazione dell'acquisto di monete" },
            { v: 317, name: "Restituzioni di acquisto di monete" },
            { v: 318, name: "Coin Buy to Account" },
            { v: 319, name: "Coin Buy to Account" },
            { v: 320, name: "Blocca la vendita di monete" },
            { v: 321, name: "Detrazione delle vendite di monete" },
            { v: 322, name: "Rendimenti di vendita di monete" },
            { v: 323, name: "Coin Sell to Account" },
            { v: 324, name: "Coin Sell to Account" },
            { v: 325, name: "Tassa di gestione delle monete" },
            { v: 401, name: "I minatori si uniscono" },
            { v: 402, name: "Restituisce Miner" },
            { v: 403, name: "Entrate minerarie" },
            { v: 404, name: "Uscita mineraria" },
            { v: 405, name: "Tassa di uscita mineraria" },
            { v: 411, name: "Redditi da indebitamento" },
            { v: 412, name: "Tassa di prestito" },
            { v: 413, name: "Rimborso riuscito" },
        ],
    },
    message: {
        logout: "Esci",
        register_success: "Registrazione riuscita",
        place_inner_personal: "Inserisci prima i tuoi dati personali",
        submit_success: "Inviato con successo！",
        copy_success: "Copia riuscita！",
        copy_error: "Riproduzione non riuscita！",
        modify_success: "Modificato con successo",
        no_balance: "Saldo insufficiente per coprire il deposito cauzionale",
        palce_password: "Inserisci la tua password",
        trade_success: "Operazione riuscita",
        placeEnterAmount: "Inserisci l'importo",
        tipTit1: "Sei sicuro di voler pagare per questo ordine?",
    },
    web: {
        account: {
            menu1: "Informazioni sul conto",
            menu2: "I miei beni",
            menu3: "Rapporti contabili",
            menu4: "Sicurezza del conto",
            menu5: "Notifiche",
            menu6: "Centri di attività",
            menu7: "Il mio portafoglio",
            menu8: "Ritiro",
            walletAdd: "Aggiungi nuovo portafoglio",
            report: {
                history_tit: "Rapporto sullo storico delle transazioni",
                stream_tit: "Relazione sul flusso di fondi",
                desc: "Selezionare l'intervallo di date appropriato per esportare il report dell'account. (Avrai accesso alle informazioni dell'account per gli ultimi 1 anno)",
                select_date: "Seleziona intervallo di date",
                week: "La scorsa settimana",
                month: "Circa un mese",
                threeMonth: "Tre mesi",
                sixMonth: "Sei mesi",
                time: "Tempistica",
                view_report: "Mostra rapporto",
                email_send: "Invia via e-mail",
                dialog_tit: "Rapporti contabili",
                dialog_p1:
                    "StratTrade Holding Ltd è registrata nelle Isole Cayman (numero SIB: 1612446) e tratta le tue transazioni come entità commerciale.",
                tit2: "Rapporto sullo storico delle transazioni Mitade",
                tit4: "Rapporto Mitade Flusso di fondi",
                tit3: "Informazioni sul conto",
                label1: "Nome del conto",
                label2: "ID account",
                label3: "Valuta del conto",
                label4: "Tempo di segnalazione",
                label5: "Periodo di riferimento",
                label6: "Flusso di cassa",
                empty: "Non hai scambi chiusi",
                email_des: "La segnalazione verrà inviata al tuo indirizzo e-mail",
                tab1: "Relazioni",
                tab2: "Dichiarazione giornaliera",
                tab3: "Conto mensile",
            },
            account: {
                tit1: "Benvenuti a StratTrade",
                tit2: "Puoi iniziare a fare depositi e a fare trading nell'ambiente live dopo aver completato la verifica dell'ID.",
                p1: "Apri un conto live",
                p2: "Verifica dell'identità",
                btn1: "Verifica ID",
                tit3: "Informazioni personali",
                label_name: "Nomi",
                label_email: "E-mail",
                label_address: "Indirizzo di residenza",
                tit4: "Informazioni sul conto",
                label_account: "Numero di conto",
                label_currency: "Valuta di base",
                label_company: "Entità di servizio corrente",
                company_tip:
                    "StratTrade Holding Ltd of Companies è autorizzata e autorizzata dall'Autorità Monetaria delle Isole Cayman (CIMA).",
                tit5: "Apri un conto live",
                tit6: "Profilo completo",
                tit7: "Completa le tue informazioni di base",
                tit8: "Valutazione dei rischi",
                tit9: "Valutare la capacità di sopportare i rischi",
                tit10: "Verifica dell'identità",
                tit11:
                    "Completa la verifica del tuo ID in conformità con i requisiti normativi",
                p3: "Selezionare uno dei seguenti documenti giustificativi per la verifica dell'identità",
                type1: "ID nazionale",
                type2: "Patente di guida",
                type3: "Passaporto",
                p4: "Confermo che tutte le informazioni e i documenti forniti sono completi, veritieri e accurati. Prendo atto di eventuali modifiche nelle informazioni fornite, informerò Mitrade entro 30 giorni.",
                next: "Passo successivo",
                btn2: "Certificato",
                modify_personal_tit: "Modifica le tue informazioni",
            },
            activity: {
                tit: "Centri di attività",
                desc: "Puoi partecipare ad attività per ricevere ricompense per aumentare il tuo reddito da investimento",
            },
            capital: {
                real_trade: "Real Deal",
                label_nowAccount: "Conto corrente",
                label_tradeAccount: "Conto di trading",
                label_currency: "Valuta di base",
                deposit_withdraw_record: "Registri di deposito e prelievo",
                capital_flow_details: "Dettagli sul flusso dei fondi",
            },
            notification: {
                tit: "Impostazioni notifiche",
                tit1: "Apri canali di notifica",
                email: "Indirizzo e-mail",
                message: "Messaggi di testo",
                push: "Spingi",
                verify: "Convalida (una teoria)",
                verified: "Convalida (una teoria)",
                p1: "Si prega di aprire/verificare i seguenti canali di notifica per tenere traccia delle notizie importanti!",
                type1: "Marketing",
                tit2: "Notifica delle attività di commercializzazione",
                desc2:
                    "Informazioni speciali della piattaforma, attività operative e altre notizie",
                tit3: "Istruzioni per l'uso",
                desc3: "Materiale di conoscenza ricco e specializzato",
            },
            security: {
                tit: "Crittografica",
                isSet: "Configurato",
                set: "Vai avanti e prepara",
                verify: "Convalida (una teoria)",
                verified: "Validato",
                tit1: "Certificazione di sicurezza",
                email: "Indirizzo e-mail",
                phone: "Numero di telefono cellulare",
                phone_tit: "Legatura dei numeri di telefono cellulare",
                password_tit: "Cambia la password",
            },
        },
        market: {
            hot: "Popolare",
            prev: "Pagina precedente",
            next: "Pagina successiva",
            line: "Ripartizione del tempo",
            main_subplot: "Indicatori grafici principali e supplenti",
            main: "Indicatore grafico principale",
            subplot: "Indicatore grafico",
            go_trade: "Passa alla pagina delle transazioni",
        },
        news: {
            tip: "Disclaimer: Le informazioni di cui sopra sono informazioni generali solo per riferimento e non devono essere utilizzate come base per decisioni di trading.",
        },
        trade: {
            state1: "Aziende",
            state2: "Ordine in sospeso",
            state3: "Storia",
            state: "Modalità",
            state4: "Posizione chiusa",
            state5: "Annullato",
            type: "Tipologia",
            type1: "Pieno",
            type2: "Vendi",
            type3: "Compra",
            left_label1: "Self-service",
            left_label2: "Visualizzati di recente",
            left_type1: "Modalità colonna compatta",
            left_type2: "Modello colonnare allentato",
            left_type3: "Mostra grafico",
            all: "Pieno",
            right_bp: "Il raccolto di ieri",
            right_sp: "I giorni moderni",
            right_height: "Supreme",
            right_low: "Più basso",
            remind_tit: "Nuovi avvisi",
            remindList: "Elenco promemoria",
            remind_btn: "Nuovi avvisi",
            right_tab1: "Operazioni",
            right_tab2: "Particolari",
            right_sell: "Vendi",
            right_buy: "Compra",
            right_type: "Tipologia",
            right_type1: "Elenco dei mercati",
            right_type2: "Ordine in sospeso",
            right_priceMoney: "Prezzo dell'ordine in sospeso",
            right_pointerNumber: "Numero (lotti)",
            right_lever: "Pry bar",
            right_balance: "Disponibile",
            right_stop_profit: "Prendere profitto",
            right_stop_loss: "Stop-loss",
            right_profit_loss: "P/L",
            now_order: "Ordina ora",
            confirm_order: "Conferma dell'ordine",
            right_tit1: "Strategie di trading",
            right_rinei: "Tra pochi giorni",
            right_short: "A breve termine",
            right_middle: "Mid-term",
            right_time: "Tempo di rilascio",
            right_tit2: "Strategia alternativa",
            right_tit3: "Revisione tecnica",
            right_tit4: "Digitale",
            right_tit5: "Atmosfera commerciale",
            right_label_sell: "Venditore (di merci)",
            right_label_buy: "Acquirente",
            right_tip:
                "A titolo puramente informativo, non una posizione della Divisione",
            right_tip2: "Aggiornamento ogni 15 minuti",
            right_tit6: "Variazione dei prezzi",
            minute: "Minuti",
            today: "Attualmente",
            right_tit7: "Variazione dei prezzi",
            now_price: "Prezzi correnti",
            right_low_price: "Prezzo più basso",
            right_height_price: "Prezzo più alto",
            right_tit8: "Informazioni sul contratto",
            right_tit9: "AUD/CHF",
            right_label1: "Numero di singole operazioni",
            right_label2: "Leva massima",
            right_label3: "Dimensione del contratto",
            right_label4: "Numero massimo totale di posizioni",
            right_label5: "Diffusione fluttuante",
            right_label6: "carica notturna",
            hand: "Hand",
            right_collection_time: "Tempo di raccolta",
            right_tip3: "Base delle commissioni: dalla dimensione complessiva dell'operazione",
            right_label7: "Margine ratio",
            right_label8: "Margine di mantenimento",
            right_label9: "Orari di negoziazione",
            right_label10: "Sessione di trading corrente",
            right_label11: "Prossima sessione di trading",
        },
    },
    home: {
        banner_p1: "",
        banner_p2: "Commercio ",
        banner_p3: "Forex, materie prime,",
        banner_p4: "Indici, azioni e altro ancora",
        banner_p5:
            "Puoi esplorare i mercati finanziari più caldi del mondo. StratTrade offre 0 commissioni, spread estremamente vantaggiosi e configurazioni flessibili di leva.",
        hot: "Popolare",
        tit1: "Transazioni più amichevoli",
        p1: "Interfaccia semplice e intuitiva per un facile utilizzo",
        p2: "Supporto web, app mobile e desktop, trading a portata di mano",
        p3: "Strumenti di gestione del rischio quali stop loss/trailing stop",
        p4: "Grafici tecnici multipli e calendari finanziari, notizie in tempo reale",
        p5: "Email, SMS e notifiche push in tempo reale",
        p6: "Miglioramento continuo per una migliore esperienza di trading",
        btn1: "Esplora la nostra piattaforma",
        row3_tit: "Scambia liberamente in qualsiasi momento e ovunque",
        row3_tip: "Supporto web, app mobile e desktop, trading a portata di mano",
        row3_tit2: "Scansiona il codice da scaricare",
        row3_tit3: "Desktop",
        row3_tit4: "Mobile",
        regular_tit: "Piattaforma internazionale affidabile",
        regular_tip:
            "Ci impegniamo a fornire un ambiente di trading sicuro e responsabile per i nostri clienti.",
        regular_tit1: "Regolamentato da un organismo autorevole",
        regular_p1:
            "Regolamentazione e autorizzazione dell'autorità del settore, conquista la fiducia dei clienti globali",
        regular_tit2: "Tutela della sicurezza dei fondi",
        regular_p2:
            "I depositi dei clienti al dettaglio sono segregati in conti fiduciari secondo necessità in conformità con i requisiti normativi",
        regular_tit3: "Protezione contro il bilanciamento negativo",
        regular_p3:
            "Fornire protezione del saldo negativo del conto, saldo negativo in modo tempestivo per cancellare lo zero, in modo che la tua perdita non superi l'importo di entrata, tranquillità di trading",
        regular_tit4: "5 * 24 ore di servizio clienti",
        regular_p4:
            "Sincero e professionale team di servizio clienti, 24 ore di supporto online, felice di risolvere qualsiasi problema hai!",
        service_tit: "Altri servizi per aiutarti",
        service_tit1: "Strategie di trading",
        service_p1:
            "Strategie di trading in tempo reale per aiutarti a comprendere le ultime tendenze del mercato, comprendere meglio i tempi di trading.",
        service_tit2: "Conoscenza del trading",
        service_p2:
            "Impara a fare trading gratis con StratTrade e migliora le tue abilità di trading.",
        service_tit3: "Gestione dei rischi",
        service_p3:
            "Scopri gli strumenti gratuiti di gestione del rischio offerti da StratTrade per proteggere meglio i tuoi asset.",
        step_tit: "Facile e conveniente aprire un conto",
        step_tip: "Tre semplici passaggi per aprire un conto in pochi minuti",
        step_tit1: "Iscrizione",
        step_p1: "Compila i dati e invia la tua candidatura",
        step_tit2: "Deposito",
        step_p2: "Deposito rapido di fondi attraverso una varietà di metodi",
        step_tit3: "Inizio delle negoziazioni",
        step_p3: "Scopri le opportunità di trading e ordina rapidamente",
        award_tit: "I nostri successi",
        award_p1:
            "Abbiamo sempre perseguito l'eccellenza, lottando per l'eccellenza e ci impegniamo a fornire ai nostri clienti servizi di transazione di qualità.",
        award_p2:
            "Il marchio StratTrade è onorato di ricevere un premio da una prestigiosa organizzazione del settore, riconoscendo il costante impegno e l'impegno del team nei confronti dei propri clienti.",
        row7_tit: "Informazioni finanziarie globali in tempo reale",
        row7_tit1: "Notizie in tempo reale",
        exchange_tit1: "Formare il settore delle opzioni crittografiche.",
        exchange_tit2: "Dal 2016.",
        exchange_subtit: "StratTrade Exchange",
        exchange_card1_tit: "Per principianti.",
        exchange_card1_p:
            "Inizia le tue abilità di trading con il nostro corso di opzioni gratuite. Sperimenta sulla nostra piattaforma di test senza alcun rischio. Utilizza il nostro supporto 24 ore su 24, 7 giorni su 7 per qualsiasi domanda. Il tuo viaggio sui derivati inizia qui.",
        exchange_card2_tit: "Operatori esperti.",
        exchange_card2_p:
            "Immergiti nel nostro ambiente di trading istituzionale. Utilizza gli strumenti più avanzati del settore nel mercato delle opzioni più liquido del mondo.",
        exchange_card3_tit: "Per le istituzioni.",
        exchange_card3_p:
            "Scopri perché le istituzioni più importanti hanno preferito StratTrade dal 2016. Colo Services in LD4, Portfolio Margin Model, Block Trades, Combo, la migliore liquidità, Multicast e altro ancora.",
    },
    learn: {
        education: {},
    },
    login: {
        click_download: "Clicca per scaricare",
        p1: "Scambia sempre e ovunque",
        p2: "Fai trading più veloce e più intelligente",
        p3: "0 scambio di commissioni",
        p4: "L'investimento più caldo del mondo",
        renderDom1: "Valuta estera",
        renderDom2: "Borsa statunitense",
        renderDom3: "Platinum",
        renderDom4: "Petrolio greggio",
        renderDom5: "Indici",
        renderDom6: "Cryptocurrency",
        login: "Accedi",
        elail: "Email",
        phone: "Numero di telefono",
        no_account: "Nessun conto？",
        go_register: "Vai alla registrazione",
        register: "Iscrizione",
        now_register: "Registrati ora",
        have_account: "Ho già un account？",
        go_login: "Vai avanti e accedi.",
    },
    about: {
        serviceFee: "Costo del servizio",
        introduction: "Introduzione della piattaforma",
        trade_platform: "Piattaforma di trading",
        complaint_process: "Processo di reclamo",
        page: "Pagina web",
        place_search: "Cerca quello che vuoi sapere",
        no_answer: "Non riesco a trovare la risposta che ti serve?",
        contact_us: "Contattaci",
        awards: {
            h1: "Premi e onori",
            p1: "Nella nostra ricerca di eccellenza e dedizione per fornire ai nostri clienti servizi di trading di qualità, il marchio StratTrade è onorato di ricevere un premio da una prestigiosa organizzazione del settore, riconoscendo i continui sforzi e l'impegno del team nei confronti dei nostri clienti.",
            p2: "Oppure sperimenta immediatamente la nostra pluripremiata piattaforma di trading online!",
        },
        charges: {
            h1: "Tariffe e oneri",
            p1: "Come parte dei nostri sforzi per migliorare l'esperienza del cliente, StratTrade vuole fornire ai nostri clienti un servizio di trading più conveniente. Ci impegniamo ad essere aperti e trasparenti sulle nostre tariffe e spese, senza costi nascosti. I principali costi di transazione per i nostri clienti sono lo spread bid/ask e gli interessi di posizione overnight.",
            h2: "Prima di tutto, quando si fa trading con StratTrade si può godere",
            li1: "Quota istantanea del prodotto",
            li2: "Indicatori tecnici specializzati",
            li3: "Analisi di mercato esclusiva",
            free: "Gratuito (a pagamento)",
            view: "Guarda in alto",
            h3: "Differenza offerta-domanda",
            p2: "StratTrade addebita uno spread bid/ask come compensazione per i suoi servizi. Questa commissione si riflette nel preventivo del prodotto e si paga effettivamente lo spread quando si apre una posizione. Poiché la commissione di spread non è fissa, ti consigliamo di andare alla pagina di preventivo specifico del prodotto, o di accedere al",
            h4: "Spese di deposito e prelievo",
            p3: "Al fine di ridurre al minimo i costi per i nostri clienti, nella maggior parte dei casi non addebitiamo l'accesso ai fondi. Tuttavia, una società o una banca di terze parti può addebitarti una commissione per effettuare un deposito o un prelievo, come una commissione bancaria di transito.",
            p4: "Potete chiedere ai nostri specialisti del servizio clienti informazioni sulle spese da effettuare.",
            p5: "Ad esempio attraverso transazioni internazionali con carta di credito, trasferimenti bancari in/out o transazioni in valute non supportate (conversione Forex), ecc..",
            h5: "Costi di posizione overnight",
            p6: "Se hai ancora una posizione alle 22:00 GMT (22:00 GMT), ti verrà addebitata/depositata una commissione notturna. Le richieste relative alle spese notturne per ogni prodotto possono essere effettuate sulla pagina specifica del preventivo del prodotto, oppure accedendo al",
            h6: "Altre spese",
            p7: "Nessun'altra tassa. La nostra politica tariffaria è completamente trasparente e tutti gli articoli che richiedono una tassa saranno annunciati e comunicati in anticipo, quindi non c'è bisogno di preoccuparsi di tasse nascoste.",
        },
        contact: {
            h1: "Contattaci",
            address: "Indirizzo",
            custom_email: "Email di contatto del cliente",
            business_email: "Cooperazione commerciale Email",
            h2: "Scrivici.",
            p1: "Se hai domande sui nostri servizi, compila il modulo sottostante per inviare la tua domanda e uno dei nostri specialisti del servizio clienti risponderà al più presto. Inoltre, per iscriverti alle strategie di trading giornaliere fornite dalla nostra partnership con Trading Central, seleziona 'Iscriviti all'analisi giornaliera di mercato' nel tipo del modulo sottostante e compila la versione linguistica che desideri ricevere e ti registreremo. * Il servizio di abbonamento è disponibile solo per i clienti reali StratTrade esistenti.",
            h3: "Procedure di rappresentanza",
            p2: "Tutti i reclami avremo un team specializzato per voi per indagare e risolvere, i dettagli possono essere riferiti a",
        },
        faq: {
            h1: "Problemi comuni",
            tit1: "Qual è il senso di un account demo？",
            tit2: "Come aprire un conto reale？",
            tit3: "Come creare un ordine di mercato？",
            tit4: "Perché non posso fare un ordine？",
            p1: "I conti demo sono per lo più gli stessi dei conti reali in termini di interfaccia di trading, dati e operazioni. Gli account demo contengono $50.000 in fondi virtuali e sono progettati per consentire ai clienti di familiarizzare con le funzionalità della piattaforma attraverso operazioni simulate senza rischiare i loro fondi.",
            p2: "Per aprire un conto live, segui i passaggi seguenti:",
            p3: "1. Entra nella pagina di registrazione StratTrade e segui le istruzioni: registra la tua email/numero di cellulare, imposta la password di accesso e invia la conferma per creare automaticamente un [Conto Demo].",
            p4: "2. Nella pagina Conto Demo, seleziona 'Passa a Conto Reale' e segui la procedura per aprire un 'Conto Reale'.",
            p5: "Prima di aprire un conto live, ti consigliamo di leggere e comprendere le informazioni legali associate al trading.",
            p6: "Si prega di notare che è possibile depositare sul proprio conto live solo dopo aver completato la verifica dell'identità.",
            p7: "Clicca su 'Trade' (Trade) per visualizzare tutti i prodotti disponibili per il trading sulla piattaforma, puoi inserire il codice o il nome del prodotto nella casella di input a destra per cercarlo, selezionare un prodotto e cliccare su 'VENDI/ACQUISTA' (VENDI/ACQUISTA) per visualizzare la finestra di trading, nella quale puoi vedere il prezzo corrente e il margine stimato richiesto. È possibile regolare manualmente il numero di operazioni da aprire, impostare uno stop loss e prendere profitto per il controllo del rischio, quindi fare clic su 'VENDI/ACQUISTA' per completare l'apertura della posizione. Si prega di notare che i prezzi di tutti i prodotti finanziari fluttuano e si aggiornano con il mercato in qualsiasi momento e il mercato potrebbe essere cambiato prima di premere il pulsante ACQUISTA / VENDI.",
            p8: "Prima di tutto, controlla se la connessione Internet è normale. Quando si negoziano varietà di mercati temporaneamente chiuse quotazioni o mercati chiusi possono causare l'impossibilità di effettuare ordini. Per maggiori dettagli, contatta il nostro servizio clienti online per ulteriore assistenza.",
        },
        fundSecurity: {
            h1: "Sicurezza finanziaria",
            p1: "StratTrade è autorizzata e regolamentata da autorevoli organismi di regolamentazione. La conformità è al centro di tutto ciò che facciamo e della nostra cultura. La sicurezza dei vostri fondi è fondamentale e tutti i depositi dei clienti sono tenuti in posizioni segregate.",
            p2: "I depositi dei clienti al dettaglio sono tenuti separatamente in conti fiduciari se necessario in conformità con i requisiti normativi",
            p3: "StratTrade non utilizza fondi dei clienti per attività commerciali diverse da quelle consentite dalle leggi e dai regolamenti.",
            p4: "StratTrade non specula in nessun mercato",
            p5: "Revisione indipendente da parte di un'impresa contabile terza",
        },
        help: {
            h1: "Benvenuti nel Centro Assistenza StratTrade!",
        },
        media: {
            h1: "Media Center",
            p1: "Visualizza informazioni, comunicati stampa, caratteristiche multimediali, annunci e altre informazioni relative al marchio StratTrade.",
        },
        mitrade: {
            h1: "Informazioni su StratTrade",
            view_certificate: "(Metodi di identificazione)",
            p1: "StratTrade è una società di tecnologia finanziaria autorizzata e regolamentata da istituzioni autorevoli, focalizzata sul fornire agli investitori un'esperienza di trading semplice e conveniente. L'innovativa piattaforma di trading diversificata StratTrade ha ripetutamente vinto premi come Best Mobile Trading Platform e Most Innovative Brokerage.",
            p2: "Attraverso la piattaforma StratTrade, è possibile investire e negoziare in una gamma più ampia di mercati finanziari internazionali, comprendendo azioni, indici, materie prime, cambi e centinaia di altre varietà popolari. Non solo limitato alle piattaforme di trading mobile e web-based di facile utilizzo, StratTrade offre anche costi di trading competitivi, esecuzione rapida delle negoziazioni, eccellente servizio clienti e una vasta gamma di supporto informativo, aiutandoti a cogliere opportunità di investimento e trading tempestive.",
            p3: "StratTrade è strettamente regolamentato dall'autorità del settore e da più agenzie. StratTrade International è autorizzata e regolamentata dalla Financial Services Commission (FSC) di Mauritius con il numero di licenza GB20025791. https://www .fscmauritius.org",
            p4: "MStratTrade Global è autorizzata dalla Australian Securities and Investments Commission (ASIC) con la licenza per i servizi finanziari (AFSL 398528). Per ulteriori informazioni sulle licenze australiane, visitare il sito web ASIC www.asic.gov.au.",
            p5: "Itrade Holding è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con licenza SIB n. 1612446. Per ulteriori informazioni sulla licenza Caymanian, visitare il sito ufficiale CIMA www.cima.ky.",
            p6: "Tutte le operazioni commerciali di StratTrade sono condotte sotto stretta supervisione e nel rispetto di tutte le normative.",
            h2: "Contesto di StratTrade",
            p7: "StratTrade è stata fondata a Melbourne, Australia da un team di professionisti del trading finanziario e fintech esperti e competenti con la visione di rendere il trading più facile e facile da usare!",
            p8: "La nostra strategia olistica e modello di business ci consentono di avere un'osservazione globale e approfondita e conoscenza dello sviluppo del settore e della domanda del mercato, in modo da poter allocare le risorse in modo più mirato, innovare continuamente la tecnologia e ottimizzare l'efficienza e continuare a offrire ai nostri clienti un'esperienza di trading più conveniente e amichevole. ",
            h3: "Perché Strattrade",
            why1: "Condizioni di negoziazione a bassa soglia",
            why2: "Regolamentato dalle autorità del settore",
            why3: "Piattaforma di trading semplice e intuitiva",
            why4: "Alto livello di supporto online",
            why5: "Costi di transazione competitivi",
            why6: "Protezione contro il bilanciamento negativo",
            h4: "Premi e onori",
            p9: "Abbiamo sempre perseguito l'eccellenza, lottando per l'eccellenza e ci impegniamo a fornire ai nostri clienti servizi di transazione di qualità.",
            p10: "Il marchio StratTrade è onorato di ricevere un premio da una prestigiosa organizzazione del settore, riconoscendo il costante impegno e l'impegno del team nei confronti dei propri clienti.",
            new_add: "Nuovi utenti in tutto il mondo",
            ol1: "Miglior fornitore di notizie e analisi",
            ol2: "Il miglior broker Forex in Asia",
            ol3: "Australian Forex Customer Satisfaction Awards",
            ol4: "Migliore applicazione mobile",
            ol5: "Il broker australiano in più rapida crescita",
            h5: "La filosofia operativa di StratTrade",
            tip_tit1: "Obblighi",
            tip_tit2: "Semplice ma bello",
            tip_tit3: "Aperto (non riservato)",
            tip_tit4: "Blaze new trails",
            tip_p1:
                "Tutto il trading comporta rischi e StratTrade ha adottato ulteriori misure per fornire un ambiente di trading responsabile per i suoi clienti. StratTrade è strettamente regolamentato dalle autorità del settore, da più organizzazioni e opera secondo standard di sicurezza e stabilità di alto livello. La nostra piattaforma continua a fornire una serie di strumenti di trading responsabili tra cui la gestione del rischio.",
            tip_p2:
                "Semplicità è bellezza, dettagli fanno eccellenza, StratTrade è dedicata alla facilità d'uso e all'esperienza utente in ogni dettaglio. Continuiamo ad ottimizzare la nostra piattaforma e i nostri servizi nella speranza che tutti, dai principianti agli investitori esperti, possano sfruttare le opportunità di trading attraverso la piattaforma StratTrade.",
            tip_p3:
                "Al fine di consentire ai nostri clienti di beneficiare appieno di un ambiente di trading equo ed efficiente, StratTrade segue un sistema di autoregolamentazione rigoroso e trasparente, fornisce informazioni complete e trasparenti sui prodotti della piattaforma, l'ambiente dei prezzi e i dati, e tutte le commissioni sono chiaramente e apertamente divulgate per garantire la completa sincerità e trasparenza dell'intero processo di servizio.",
            tip_p4:
                "StratTrade Crediamo fermamente che il progresso tecnologico, l'innovazione finanziaria e i servizi più ampi siano le forze trainanti della crescita e solo allora potremo creare e condividere continuamente valore. Per questo motivo, incoraggiamo il pensiero aperto, promuoviamo continuamente l'innovazione e miriamo a diventare innovatori e leader nella tecnologia finanziaria.",
            row6_tit1: "La nostra missione",
            row6_tit2: "La nostra visione",
            row6_tit3: "Opportunità di lavoro",
            row6_p1:
                "Fornire un ambiente commerciale equo, efficiente e trasparente che non sia limitato dallo spazio, dal tempo e dal mercato per un maggior numero di persone che vogliono operare e guidare l'industria nell'innovazione finanziaria e tecnologica.",
            row6_p2:
                "Crediamo che il progresso tecnologico, l'innovazione finanziaria e i servizi diversificati siano le forze che guidano la nostra crescita e ci permettono di continuare a creare e condividere valore nella società.",
            row6_p3:
                "A tal fine, incoraggiamo l'apertura mentale e promuoviamo l'innovazione, puntando a diventare, attraverso la crescita e il progresso continui, il",
            row6_p4: "La società di cambio leader mondiale",
            row6_p5:
                "Siamo un team globale in 7 paesi e con un team in rapida crescita, siamo sempre alla ricerca di professionisti che possano unirsi a noi!",
            row6_p6:
                "Inviaci il tuo curriculum aggiornato e perché vuoi lavorare con noi:",
            row6_p7: "In alternativa, puoi anche saperne di più sul",
            row6_p8: "Forse",
            row6_p9: "Guarda i nostri posti vacanti.",
            row6_p10:
                "StratTrade protegge rigorosamente la sicurezza delle tue informazioni personali. StratTrade non ti chiederà informazioni finanziarie durante il processo di candidatura. Non richiediamo alcuna informazione finanziaria, carta di credito, conto bancario o pagamento di alcun tipo per il nostro reclutamento.",
            row6_p11:
                "Se sospettate che una posizione sia fraudolenta, vi preghiamo di contattarci attraverso il",
            row6_p12:
                "Contatta il Dipartimento Risorse Umane di StratTrade. Se confermi di essere stato ingannato da un falso annuncio di lavoro, contatta il tuo dipartimento locale dell'organizzazione interessata.",
        },
    },
    product: {
        row2: {
            tit: "Varietà commerciali",
            sell: "Vendi",
            buy: "Compra",
            trade: "Operazioni",
            more_product: "Altri prodotti commerciali",
        },
        row3: {
            tit: "Supporta transazioni multiple di dispositivi",
            tit1: "Supporta transazioni multiple di dispositivi",
            p1: "StratTrade fornisce piattaforme di trading basate sul web, mobili e desktop. Puoi fare trading in qualsiasi momento, ovunque.",
            download: "Scaricamento",
        },
        row4: {
            tit: "Seleziona StratTrade",
            tit1: "Piattaforma semplice e intuitiva",
            p1: "Integrazione di quotazioni, trading, gestione account, informazioni, gestione del rischio, potenti funzioni, per ottenere una migliore esperienza utente",
            tit2: "Costi di transazione competitivi",
            p2: "0 commissioni di trading, spread bassi molto competitivi e trasparenti, SWAP bassi, in modo da poter godere di trading a basso costo!",
            tit3: "Condizioni di negoziazione a bassa soglia",
            p3: "La dimensione minima del lotto per ogni transazione è di 0,01 lotto e puoi aprire una posizione con un margine basso.",
            tit4: "Regolamentato dall'autorità",
            p4: "Autorizzati e regolati dalle autorità del settore, tutti i depositi dei clienti sono conservati separatamente e segregati per proteggere la sicurezza del patrimonio dei clienti",
            tit5: "Protezione contro il bilanciamento negativo",
            p5: "Il tuo conto non perderà mai più del suo principale in qualsiasi condizione di mercato e i saldi negativi saranno eliminati in modo tempestivo per migliorare la tua capacità di gestione del rischio.",
            tit6: "Alto livello di supporto online",
            p6: "Fornire il servizio clienti online di risposta rapida, fornirà un servizio migliore attraverso gli sforzi incessante del team di professionisti",
        },
        row5: {
            tit: "Questioni correlate",
        },
        commodities: {
            title: "Commercio di merci",
            p: "Materie prime come i metalli preziosi e l'energia sono considerate una parte importante degli investimenti diversificati in quanto sono considerate sia a prova di inflazione che hanno la possibilità di aggiungere valore all'investimento. Tra questi, oro, argento e petrolio sono materie prime scambiate in enormi volumi, e i loro prezzi aperti e trasparenti e l'elevata liquidità sono stati ampiamente accolti dagli investitori. I prezzi delle materie prime fluttuano significativamente a causa di fattori quali domanda e offerta, situazioni economiche e politiche e valute, fornendo opportunità di rischio-ritorno.",
        },
        forex: {
            title: "Commercio in valuta estera",
            p: "Il trading Forex è la conversione di una valuta in un'altra, e il mercato dei cambi è il mercato finanziario più scambiato al mondo. Il mercato dei cambi ha un gran numero di partecipanti che commerciano attraverso di esso per effettuare pagamenti, proteggere contro il rischio di movimenti valutari, o per realizzare un profitto. Il trading Forex avviene attraverso una rete di banche, istituzioni e singoli trader in tutto il mondo, con fluttuazioni dei prezzi 24 ore al giorno, 5 giorni alla settimana, fornendo opportunità di rischio-ricompensa.",
        },
        indices: {
            title: "Indici",
            p: "Un indice azionario è un valore statistico che rappresenta un particolare tipo di azione caratteristica in una particolare borsa ed è un indicatore del livello complessivo dei prezzi e del movimento di un particolare tipo di azione costituente sul mercato. Il trading di indici azionari che riflettono le opportunità di investimento nel mercato o nel settore generale può ridurre il rischio di investire in singole azioni. Gli indici più popolari nei mercati statunitensi, europei, asiatici e australiani, come Australia 200, U.S. Tech 100, Hong Kong 50, Germany 30, ecc., offrono diverse opportunità nei mercati azionari globali.",
        },
        shares: {
            title: "Trading di azioni",
            p: "Un titolo è un titolo negoziabile emesso in una borsa. Al fine di raccogliere capitali, le società distribuiscono la proprietà della società attraverso le borse quotate utilizzando i certificati. Solo nel 2019, il trading azionario mondiale ha totalizzato oltre 60 trilioni di dollari, che è così grande e liquido che rimane uno degli strumenti finanziari più popolari agli occhi degli investitori di tutto il mondo, anche dopo molti anni. Sulla piattaforma StratTrade, i trader possono utilizzare la leva finanziaria per negoziare le variazioni di prezzo delle azioni. I prezzi delle azioni sono influenzati da una varietà di fattori come la macroeconomia, le prospettive del settore e le operazioni aziendali, rendendoli altamente volatili e ci sono sempre opportunità di trading di investimento.",
        },
        coins: {
            title: "Criptovalute",
            p: "Le criptovalute sono una valuta digitale creata attraverso il codice. Operano autonomamente al di fuori dei sistemi bancari e governativi tradizionali. Come suggerisce il nome, le criptovalute utilizzano la crittografia per proteggere le transazioni e fornire la creazione di altre unità, con Bitcoin che è l'originale e di gran lunga la criptovaluta più famosa, Bitcoin è stato creato da Satoshi Nakamoto e lanciato nel gennaio 2009. Ancora oggi, rimane sconosciuto se il nome Satoshi Nakamoto si riferisca a una persona o a un gruppo di persone. Bitcoin è considerato la prima criptovaluta decentralizzata. Come tutte le criptovalute, è controllato attraverso un database di transazioni blockchain che funge da registro pubblico distribuito. Ad oggi, più di 1.000 criptovalute sono scambiate online.",
        },
    },
    trade: {
        data_sources: "Fonti di dati",
        tip: " Le informazioni di cui sopra sono solo a titolo di riferimento. StratTrade non garantisce l'accuratezza, la tempestività o la completezza delle informazioni e non si dovrebbe fare affidamento indebito sulle informazioni fornite. Queste informazioni non includono i nostri record di prezzo o qualsiasi offerta o sollecitazione a negoziare strumenti finanziari. StratTrade non è una società di consulenza finanziaria e fornisce solo servizi di natura esecutiva. Raccomandiamo ai lettori di chiedere il proprio consiglio di investimento. Si prega di consultare il nostro disclaimer completo.",
        tip2: "Aggiornato ogni venerdì alle 15:00 (GMT)",
        tip3: "Trading Perspectives è uno strumento per prevedere il sentiment del trading di mercato, riflettendo le opinioni a breve e medio termine degli esperti del settore e le previsioni di tendenza per ogni prodotto caldo.",
        analysis: {
            tit: "Strategie di trading",
            all: "Pieno",
            day: "Tra pochi giorni",
            tit1: "Strategie di trading",
            tit2: "Revisione tecnica",
        },
        calendar: {
            tit: "Calendario finanziario",
            prev_week: "La scorsa settimana",
            next_week: "La prossima settimana",
            place_date: "Data di ricerca",
            select: "Opzioni",
            select1: "Il nostro paese",
            select2: "Significato",
            start_time: "Orario di inizio",
            end_time: "Tempo di fine",
            search: "Cerca la sth.",
        },
        contract: {
            tit: "Specifiche contrattuali",
            type1: "Pieno",
            type2: "Forex",
            type3: "Commodities",
            type4: "Indici",
            type5: "Stock USA",
            type6: "Azioni australiane",
            tit1: "Principali valute estere",
            tit2: "Valuta estera minore",
            tit3: "Commodities",
            tit4: "Principali indici",
            tit5: "Indice minore",
            tit6: "Stock USA",
            tit7: "Azioni australiane",
            tit8: "Norme generali",
            p1: "Rapporto di planarità forzata",
            p2: "Validità dell'ordine in sospeso",
            p3: "Senza limiti",
            p4: "Tempo di regolamento delle commissioni per notte",
            p5: "regolamento giornaliero; Estate: 05:00, Inverno: 06:00",
            p6: "Informazioni sulla leva finanziaria, sui tassi overnight, ecc.",
            p7: "Vedi piattaforme di trading",
            p8: "Quanto sopra si applica a tutte le varietà commerciali",
            tit9: "Calendario settimanale di trading",
            p9: "Chiuso",
            p10: "Gli orari di negoziazione di cui sopra non tengono conto dell'impatto delle festività o degli aggiustamenti speciali del mercato.。",
            p11: "Raccomandazioni specifiche sui tempi di negoziazione",
            p12: "Piattaforma di accesso",
            p13: "Controllare i profili delle varietà rilevanti.",
        },
        forecast: {
            tit: "Prospettive commerciali",
            p1: "Valore medio",
            p2: "Bullish",
            p3: "Bearish",
            p4: "Sideways",
            p5: "Orientamenti",
        },
        news: {
            tit: "Notizie in tempo reale",
        },
        platforms: {
            tit: "Piattaforma di trading StratTrade",
            p: "Interfaccia semplice e intuitiva, eccellenti prestazioni di trading, soddisfa le esigenze di più investitori. Inizia a fare trading con StratTrade oggi stesso.",
            tit1: "Piattaforma di trading StratTrade comoda e facile da usare.",
            tit2: "Fai trading sempre e ovunque con l'app StratTrade!",
            li1: "Fai trading su centinaia di mercati popolari con normative autorevoli per garantire la sicurezza dei fondi",
            li2: "Monitora le tendenze del mercato in tempo reale, visualizza l'analisi del trading e sfoglia le ultime notizie finanziarie.",
            li3: "Visualizza diversi tipi di grafici di trading in qualsiasi momento e applica funzioni di grafico senza problemi",
            li4: "Scopri la pluripremiata piattaforma di trading StratTrade sul tuo dispositivo Android o iOS!",
            code_download: "Scansiona il codice da scaricare",
            tit3: "Non c'è bisogno di scaricare, fare trading online attraverso il tuo browser",
            li5: "Nessun download richiesto, fai trading online direttamente dal browser del tuo PC",
            li6: "Vincitore dei premi: Miglior broker Forex in Asia 2022, Migliore piattaforma di trading in Australia 2022, Broker Fintech Forex in più rapida crescita al mondo 2022",
            li7: "Fornisce potenti indicatori tecnici: MACD, KDJ, RSI, TRIX, DMA, CCI, ecc.",
            li8: "Dotato di strumenti di gestione del rischio come stop-loss e protezione del saldo negativo per mitigare il rischio potenziale delle tue operazioni",
            tit4: "La soluzione perfetta per le tue transazioni desktop",
            li9: "Visualizza facilmente le quotazioni di mercato nella lista auto-selezionata",
            li10: "Attiva gli avvisi per ricevere avvisi sui cambiamenti di prezzo e altre notifiche informative",
            li11: "Layout grafici multipli consentono di monitorare il mercato da diverse dimensioni temporali.",
            li12: "Specifiche contrattuali, sentiment di trading e analisi di trading tutto in un'unica interfaccia, nessuna commutazione richiesta",
            li13: "Ricevi le ultime notizie di trading direttamente in StratTrade!",
            tit5: "Perché scegliere la piattaforma di trading StratTrade?",
            row5_p1: "Sicurezza",
            row5_p2: "0% commissione",
            row5_p3: "Supporto multipiattaforma",
            row5_p4: "Scheme tecniche",
            row5_p5: "Accesso sempre e ovunque",
            row5_p6: "Vari strumenti di disegno",
        },
        quotes: {
            tit: "Dati di mercato",
            p1: "Fornisce quotazioni istantanee, sentiment di mercato e relative notizie sugli strumenti finanziari popolari del momento.",
            hot: "Popolare",
        },
        risk: {
            tit: "Sistema di gestione dei rischi",
            p1: "Tutti gli scambi comportano rischi. Utilizzando gli strumenti gratuiti di gestione del rischio forniti da StratTrade, è possibile controllare efficacemente il rischio indipendentemente dalle condizioni di mercato.",
            function_tit1: "Funzione Take Profit/Stop Loss",
            function_tit2: "Funzione di arresto trailing",
            function_p1: "Blocco dei profitti",
            function_p2: "Limite perdite",
            function_p3: "Massimizza il blocco dei profitti",
            function_p4: "Nessuna necessità di monitorare automaticamente le posizioni",
            function_p5: "Adeguamento del prezzo di chiusura delle perdite",
            row2_tit1: "Funzione Take Profit/Stop Loss",
            row2_p1:
                " Quando crei un nuovo ordine o modifichi un ordine esistente, offriamo la possibilità di impostare 'Take Profit' e 'Stop Loss'. Una volta impostato, l'ordine verrà normalmente chiuso al prezzo target che hai impostato, aiutandoti a bloccare i tuoi profitti quando raggiungi il tuo obiettivo, o a ridurre al minimo le perdite se il mercato si muove negativamente. Si prega di notare che qualsiasi ordine può essere shorted a causa della volatilità del mercato, nel qual caso il sistema non eseguirà l'ordine al prezzo predefinito, ma chiuderà la tua posizione al prezzo più favorevole successivo dal prezzo target.",
            row2_tit2: "Esempio ",
            row2_p2:
                "Il prezzo corrente di EUR/USD è 1.13816/1.13837 (Vendita/Compra). Si apre un ordine di acquisto per 1 lotto (1 lotto = 100.000 EUR) a 1.13837 e si imposta uno Stop Loss a 1.13806.",
            row2_p3:
                "In generale, quando il prezzo scende a 1,13806, il tuo ordine di stop loss verrà attivato e la posizione verrà chiusa a 1,13806 per una perdita combinata di $31.",
            row2_p4:
                "Tuttavia, quando c'è un improvviso cambiamento nell'ambiente di mercato e il prezzo scivola direttamente da 1,13837 a 1,13795, saltando direttamente il tuo obiettivo Stop Loss, il mercato salta e il sistema non sarà in grado di chiudere la posizione a 1,13806, ma sarà eseguito al prossimo prezzo più favorevole, cioè a 1,13795, e la perdita finale sarà di 42 USD.",
            row3_tit1: "Funzione di arresto trailing",
            row3_tit2: "Esempio",
            row3_p1:
                "I trailing stop (noti anche come trailing stop) sono potenti, in quanto cambiano automaticamente in linea con i tuoi profitti, permettendoti di massimizzare i tuoi profitti o minimizzare le perdite senza dover monitorare costantemente le tue posizioni. Quando crei un nuovo ordine, imposta semplicemente uno stop finale e quando il prezzo si muove a tuo favore, il tuo ordine stop viene aggiornato automaticamente con il prezzo più recente. Al contrario, quando il prezzo si muove contro di te, l'ordine stop loss verrà attivato e la posizione verrà chiusa alla distanza che hai impostato per la perdita. Si prega di notare che qualsiasi ordine può essere shorted a causa della volatilità del mercato, nel qual caso il sistema non sarà in grado di eseguire l'ordine al prezzo predefinito, ma chiuderà la posizione per voi al prezzo successivo più favorevole dal prezzo target.",
            row3_p2:
                "Il prezzo corrente di EUR/USD è 1.13816/1.13837 (vendita/acquisto). Hai stabilito un ordine di acquisto di 1 lotto a 1.13837 con una perdita di stop trailing di 100 pips (0.00100).",
            row3_p3:
                "Quando il prezzo del prodotto è a 1.13816, lo Stop Loss è 1.13716. Se il prezzo di vendita del prodotto si sposta fino a 1.13845, lo Stop Loss verrà aggiornato in base alla distanza impostata e lo Stop Loss aggiornato sarà 1.13745.",
            row3_p4:
                " Al contrario, quando il prezzo del prodotto scende da 1,13845 a 1,13745, il trailing stop verrà attivato e la posizione sarà chiusa a 1,13745.",
            tip: "Punto: Di solito, la più piccola unità di movimento nel prezzo di uno strumento finanziario è chiamata punto. Sulla piattaforma StratTrade, si riferisce all'ultima cifra o decimale del prezzo di uno strumento.",
        },
        sentiment: {
            tit: "Indice emotivo",
            type1: "Pieno",
            type2: "Forex",
            type3: "Commodities",
            type4: "Indici",
            long: "A lungo termine (finanza)",
            short: "Breve",
        },
    },
    layout: {
        aside: {
            nav1: "Operazioni",
            nav2: "Mercato",
            nav3: "Notizie",
            nav4: "College",
            nav5: "Il mio",
            newsDialog: {
                title: "News Center",
                type1: "Notifica del sistema",
                type2: "Bollettino",
                allRead: "Segna tutto come letto",
            },
            settingDialog: {
                title: "Configura",
                nav1: "Generale",
                nav2: "Mostra preferenze",
                nav3: "Informazioni di sistema",
                logout: "Esci",
                setting1: "Multilinguismo",
                setting2: "Operazioni",
                setting2_tip:
                    "Aggiungi automaticamente varietà auto-selezionate alle posizioni aperte",
                setting3: "inviare informazioni",
                setting3_tip: "Raccomandazioni funzionali",
                setting4: "Verifica del nome reale",
                setting4_tip: "Verifica del nome reale",
                setting5: "Modalità tema",
                setting5_label1: "Oscuremente",
                setting5_label2: "Colore brillante",
                setting6: "Colore crescente o decrescente",
                setting6_label1: "Lit. le salite verdi e le cadute rosse",
                setting6_label2: "Lit. arrossamenti, cadute verdi",
                setting7: "Configurazione grafico",
                setting7_label1: "Edizione standard",
                setting7_label2: "TradingView Edition",
                setting8: "Dichiarazioni e accordi",
                setting8_label1: "Informativa sulla privacy",
                setting8_label2: "Dichiarazione informativa sui prodotti",
                setting8_label3: "Dichiarazione informativa sui rischi",
                setting8_label4: "Accordo con il cliente",
                feedback_title: "Raccomandazioni funzionali",
            },
        },
        footer: {
            hot: "Varietà popolari",
            sort: "Simbolo",
            code: "Codifica",
            buy_price: "Prezzo d'acquisto",
            sell_price: "Prezzo del venditore",
            chg: "Aumento o calo del prezzo",
        },
        header: {
            place_search: "Ricerca di varietà commerciali",
            balance: "Disponibile",
            balance_tip:
                "Disponibile: la quantità di denaro sul conto che può essere utilizzata per aprire nuove posizioni",
            profit_loss: "P/L",
            asset_view: "Panoramica delle attività",
            netValue: "Valore netto",
            netValue_tip:
                "Valore netto: il valore del conto corrente, compresi gli utili e le perdite di tutte le posizioni",
            marginLevel: "Livello margine",
            margin: "Margine (nella negoziazione di derivati)",
            margin_tip: "Margine: l'importo necessario per aprire e mantenere una posizione",
            maintainsMargin: "Margine di mantenimento",
            maintainsMargin_tip:
                "Margine di mantenimento: l'importo minimo di margine che deve essere mantenuto nel tuo conto mentre mantieni tutte le tue posizioni.",
        },
    },
    table: {
        label_name: "Nome",
        label_buyPrice: "Compra",
        label_sellPrice: "Vendi",
        label_variableValue: "Modifica",
        trade: "Operazioni",
        label_code: "Simbolo",
        label_title: "Nome",
        label_start: "Significato",
        label_country: "Il nostro paese",
        label_event: "Evento",
        label_previous: "Valore precedente",
        label_predictive: "Valore previsto",
        label_announced: "Valore pubblicato",
        label_stop_loss_min: "Distanza minima per gli ordini stop loss",
        label_difference: "Spreads dinamici",
        label_contract_size: "Dimensione del contratto",
        label_max_hand: "Dimensione massima del lotto per una singola transazione",
        label_min_hand: "Dimensione minima del lotto",
        label_trade_week: "Calendario settimanale di trading",
        label_week: "1 settimana",
        label_month: "1° gennaio",
        label_quarter: "1 trimestre",
        label_dailyTrend: "Tendenza del singolo giorno",
        label_tradeId: "ID transazione",
        label_breed: "Simbolo",
        label_tradeType: "Tipo di transazione",
        label_currency: "Denaro",
        label_amount: "Denaro",
        label_balance: "Saldi",
        label_type: "Tipologia",
        label_time: "Tempistica",
        label_orderNumber: "OrderNum",
        label_pointer_number: "Handful (of)",
        label_price_money: "Apertura",
        label_stop_win_price: "Prendere profitti",
        label_stop_lose_price: "Stop-loss",
        label_openTime: "OpenTime",
        label_profit_loss: "P/L",
        label_sell_buy: "Prezzo ask/buy",
        label_chg: "Aumento o calo del prezzo",
        label_sell_price: "Prezzo del venditore",
        label_buy_price: "Prezzo d'acquisto",
        label_condition: "Prerequisito",
    },
    form: {
        label_feedback: "Questioni e raccomandazioni",
        place_feedback: "Inserisci la tua domanda o suggerimento",
        label_img: "Fotografia",
        label_img_tip: "Facoltativo, fornire uno screenshot della domanda",
        feedback_tip: "Se avete una domanda urgente, si prega di contattare",
        online: "Servizio clienti online",
        label_name: "Nomi",
        place_name: "Inserisci il tuo nome",
        label_email: "Indirizzo e-mail",
        place_email: "Indirizzo e-mail",
        message_email: "Inserisci il tuo indirizzo e-mail",
        label_nationality: "Cittadinanza",
        place_nationality: "Inserisci la tua nazionalità",
        place_questionType: "Tipo di problema",
        message_questionType: "Seleziona il tipo",
        questionType0: "Piattaforma di trading",
        questionType1: "Prodotti e commissioni",
        questionType2: "Iscriviti all'analisi giornaliera di mercato",
        questionType3: "Servizio clienti",
        questionType4: "Il resto",
        place_question: "La sua domanda",
        message_question: "Inserisci una domanda",
        submit_question: "Presentazione di domande",
        label_phone: "Numero di cellulare",
        place_phone: "Inserisci il tuo numero di cellulare",
        message_phone: "Inserisci il tuo numero di cellulare",
        label_password: "Crittografica",
        place_password: "Inserisci la tua password",
        message_password: "Inserisci la tua password",
        label_confirmPassword: "Conferma password",
        place_confirmPassword: "Inserisci nuovamente la tua password",
        message_confirmPassword: "Inserisci nuovamente la tua password",
        label_captcha: "CAPTCHA, un tipo di test sfida-risposta (calcolo)",
        place_captcha: "Inserisci il codice di verifica",
        message_captcha: "Inserisci il codice di verifica",
        get_captcha: "Codice di verifica",
        label_inviteId: "Codice invito",
        place_inviteId: "Codice invito (facoltativo)",
        to: "A",
        start_time: "Orario di inizio",
        end_time: "Tempo di fine",
        label_cardNumber: "Numero identificativo",
        place_cardNumber: "Inserisci il tuo numero identificativo",
        message_cardNumber: "Inserisci il tuo numero identificativo",
        label_cardMain: "Anteprima del documento",
        message_cardMain: "Inserire la parte anteriore del documento",
        label_cardBack: "Lato opposto di un documento",
        message_cardBack: "Inserire il retro del documento",
        confirm_modify: "Conferma delle modifiche",
        label_realName: "Nome",
        place_realName: "Inserisci il tuo nome",
        message_realName: "Inserisci il tuo nome",
        label_firstName: "Cognome",
        place_firstName: "Inserisci il tuo cognome",
        message_firstName: "Inserisci il tuo cognome",
        label_lastName: "Medio nome",
        place_lastName: "Facoltativo",
        message_lastName: "Inserisci il secondo nome",
        label_birthday: "Nascita",
        place_birthday: "Inserisci la tua data di nascita",
        message_birthday: "Inserisci la tua data di nascita",
        place_nowPsd: "Inserisci la tua password attuale",
        message_nowPsd: "Inserisci la tua password attuale",
        place_newPsd: "Inserisci una nuova password",
        message_newPsd: "Inserisci una nuova password",
        place_crmPsd: "Conferma la nuova password",
        message_crmPsd: "Conferma la nuova password",
        label_breed: "Simbolo",
        place_breed: "Inserisci una specie",
        label_phase: "Be",
        label_buyPrice: "Prezzo d'acquisto",
        label_sellPrice: "Prezzo del venditore",
        label_do: "A causa di",
        label_height: "Maggiore di",
        label_low: "Essere inferiore a",
        label_equal: "O uguale a",
        labelWalletName: "Nome portafoglio",
        messageWalletName: "Inserisci il nome del tuo portafoglio",
        placeWalletName: "Inserisci il nome del tuo portafoglio",
        labelWalletAddress: "Indirizzo portafoglio",
        messageWalletAddress: "Inserisci l'indirizzo del tuo portafoglio",
        placeWalletAddress: "Inserisci l'indirizzo del tuo portafoglio",
        labelAmount: "Denaro",
        messageAmount: "Inserisci l'importo",
        placeAmount: "Inserisci l'importo",
        placeTimeLimit: "Inserisci un limite di tempo",
        messageTimeLimit: "Inserisci un limite di tempo",
    },
    pay: {
        title: "Facile accesso ai fondi",
        p1: "Una varietà di canali di pagamento comunemente usati, supporto per deposito e prelievo rapidi",
        p2: "Alcuni metodi di pagamento potrebbero non essere disponibili nel tuo paese.",
        p3: "Benvenuto per sperimentare il servizio StratTrade!",
        btn: "Scambia ora ",
    },
    header: {
        tip1: "Il trading a leva è un prodotto finanziario complesso con un alto rischio di perdite rapide.",
        btn1: "piattaforma di trading",
        btn2: "Esci",
        btn3: "Accedi",
        BigUint64Array: "Scambia ora",
    },
    footer: {
        help: "Contattaci",
        tip1: "Segui la nostra comunità",
        tip2: "Si prega di notare che StratTrade non ha istituito un gruppo Telegram ufficiale e qualsiasi gruppo Telegram formato a nome di StratTrade è sospettato di spoofing.",
        tip3: "ATTENZIONE DEL RISCHIO: Il trading può comportare la perdita di tutti i fondi. Il trading di derivati OTC non è adatto a tutti. Si prega di leggere attentamente i nostri documenti legali prima di utilizzare i nostri servizi e assicurarsi di comprendere appieno i rischi coinvolti prima di fare trading. Non possiedi o possiedi effettivamente nessuno degli asset sottostanti.",
        tip4: "StratTrade non fornisce consigli, raccomandazioni o opinioni sull'acquisto, la detenzione o la vendita. Tutti i prodotti che offriamo sono derivati OTC basati su asset globali. Tutti i servizi forniti da StratTrade sono basati solo sull'esecuzione di ordini commerciali.",
        tip5: "StratTrade è un marchio commerciale condiviso da diverse società e gestito attraverso le seguenti società:",
        tip6: "StratTrade International Ltd è l'emittente dei prodotti finanziari descritti o disponibili per l'uso su questo sito Web StratTrade International Ltd è autorizzata e regolamentata dalla Financial Services Commission (FSC) di Mauritius con il numero di licenza GB20025791, con sede legale in 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
        tip7: "StratTrade Global Pty Ltd è registrata con ABN 90 149 011 361, licenza australiana per servizi finanziari (AFSL) numero 398528.",
        tip8: "StratTrade Holding Ltd è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con il numero di licenza SIB 1612446.",
        tip9: "Le informazioni contenute in questo sito web non sono destinate a residenti negli Stati Uniti d'America, Canada, Giappone o Nuova Zelanda, né sono destinate all'uso da parte di nessuno in qualsiasi paese o giurisdizione in cui la loro pubblicazione o uso sarebbe contrario alla legge o regolamento locale. Si prega di notare che l'inglese è la lingua principale dei nostri servizi ed è la lingua giuridicamente vincolante di tutti i nostri documenti contrattuali. Le traduzioni in altre lingue sono esclusivamente a titolo di riferimento e in caso di discrepanza tra la versione inglese e quella cinese prevarrà la versione inglese..",
        tip10:
            "Crittografia di comunicazione sicura SSL. © StratTrade Tutti i diritti riservati.",
        link1: "Informativa sulla privacy",
        link2: "Dichiarazione informativa sui prodotti",
        link3: "Processo di reclamo",
        link4: "Dichiarazione informativa sui rischi",
        link5: "Accordo con il cliente",
        toTop: "Sticky (di un forum su Internet, ecc.)",
    },
    nav: {
        tit1: "Mercati",
        tit2: "Trading",
        tit3: "Impara",
        tit4: "Su di noi",
        nav1: "Forex",
        nav2: "Indici",
        nav3: "Commodities",
        nav4: "Azioni",
        nav5: "Piattaforme di trading",
        nav6: "Strategie di trading",
        nav7: "Prospettive commerciali",
        nav8: "Calendario finanziario",
        nav9: "Notizie in tempo reale",
        nav10: "Dati di mercato",
        nav11: "Indice emotivo",
        nav12: "Sistema di gestione dei rischi",
        nav13: "Specifiche contrattuali",
        nav14: "Fondamenti di trading",
        nav15: "Approfondimento degli investimenti",
        nav16: "Accademia",
        nav17: "Informazioni su StratTrade",
        nav18: "I nostri premi",
        nav19: "Media Center",
        nav20: "Sicurezza dei fondi del cliente",
        nav21: "Commissioni e oneri",
        nav22: "Affiliati",
        nav23: "Contattaci",
        nav24: "Centro assistenza",
        nav25: "Centro assistenza",
    },

    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "ARMENIA",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrein",
        belgium: "Belgio",
        bosnia_and_Herzegovina: "Bosnia-Erzegovina",
        brazil: "Brasile",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Camerun",
        chile: "Chile",
        colombia: "Columbia",
        costa_Rica: "Costa Rica",
        croatia: "Croazia",
        cyprus: "Cipro",
        czech_Republic: "Repubblica ceca",
        denmark: "Danimarca",
        dominican_Republic: "Repubblica Dominicana",
        egypt: "Egitto",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finlandia",
        france: "Francia",
        georgia: "Georgia",
        germany: "Germania",
        ghana: "Ghana",
        greece: "Grecia",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, Cina",
        hungary: "Ungheria",
        iceland: "Islanda",
        ireland: "Irlanda",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Giappone",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonia",
        lithuania: "Lituania",
        luxembourg: "Lussemburgo",
        macao_China: "Macao_Cina",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Messico",
        moldova: "Moldavia",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "MONTENEGRO",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: " Paesi Bassi",
        new_Zealand: "Nuova Zelanda",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norvegese",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Filippine",
        poland: "Poland",
        portugal: "Portogallo",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Repubblica di Trinidad e Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arabia Saudita",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovacchia",
        slovenia: "Slovenia",
        south_Africa: "Sudafrica",
        spain: "Spagna",
        sri_Lanka: "Sri Lanka",
        sweden: "Svezia",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_Cina",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailandia",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ucraina",
        united_Arab_Emirates: "Emirati Arabi Uniti",
        united_Kingdom: "Regno Unito",
        united_States: "Stati Uniti",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Bielorussia",
        belize: "belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Isole Vergini Britanniche",
        burkina_Faso: "burkina faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Repubblica Centrafricana",
        chad: "Chad",
        comoros: "Comore",
        the_Republic_of_the_Congo: "La_Repubblica_del_Congo",
        democratic_Republic_of_the_Congo: "Repubblica democratica del Congo",
        djibouti: "Gibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Equatoriale",
        eritrea: "ERITREA",
        fiji: "Figi",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Isola di Man",
        cote_d_Ivoire: "Costa d'Avorio",
        jamaica: "Giamaica",
        jordan: "Jordan",
        lebanon: "Libano",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libia",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambico",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Repubblica_del_Niger",
        north_Korea: " Corea del Nord",
        reunion: "Reunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Siria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Isole Vergini Americane",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vaticano",
        yemen: "Yemen",
        yugoslavia: "Jugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "Cina",
    },
};
