export default {
    common: {
        learn: {
            title1: "Soldul primar",
            content1:
                "StratTrade oferă gratuit serviciul nostru de sold cash și cryptocurrency Hosted, permițându-vă să stocați banii și criptomoneda acceptată fără costuri. Puteți transfera cripto de la soldul primar al unui utilizator StratTrade la soldul primar al altui utilizator fără nicio taxă.\n" +
                "\n" +
                "Taxe pentru tranzacțiile de rețea\n" +
                "\n" +
                "Pentru tranzacțiile pe rețelele de criptomonedă (adică transferurile de criptomonedă de pe platforma StratTrade), StratTrade suportă și plătește taxe de tranzacție de rețea (de exemplu, comisioane miner). Atunci când trimiteți criptomonedă din portofelul StratTrade către un alt portofel, vom percepe o taxă bazată pe estimarea noastră a taxelor de rețea predominante pentru o trimitere individuală portofel la portofel. Cu toate acestea, taxa finală plătită de StratTrade poate fi diferită de taxa estimată din cauza unor factori precum tranzacțiile de loturi sau modificările nivelurilor de congestie a rețelei. Toate taxele sunt comunicate la momentul tranzacției.\n" +
                "\n" +
                "Creșterea eficienței și taxele de rețea\n" +
                "\n" +
                "Datorită potențialelor creșteri de eficiență în urma tranzacțiilor de lotare, suma cumulată a taxelor de rețea estimate plătite de utilizatori într-un anumit lot poate depăși taxa finală de rețea plătită de StratTrade.\n" +
                "\n" +
                "Taxa de procesare a rețelei Lightning\n" +
                "\n" +
                "Lightning Network este o rețea de microplăți care permite transferul de bitcoin fără a suporta taxa de tranzacție asociată cu blockchain Bitcoin. Atunci când trimiteți bitcoin de la StratTrade prin Lightning Network, vom percepe o taxă de procesare egală cu 0,1% din suma de bitcoin transferată.\n" +
                "\n" +
                "Adăugarea de numerar și încasarea\n" +
                "\n" +
                "Atunci când adăugați numerar sau cash out, este posibil să vi se perceapă o taxă în funcție de metoda de plată selectată. Este important să revizuiți detaliile taxei înainte de finalizarea tranzacției pentru a înțelege eventualele taxe asociate.\n" +
                "\n" +
                "Tranzacții de credit\n" +
                "\n" +
                "Dacă împrumutați USD de la StratTrade sau un afiliat al StratTrade și trebuie să vindem garanția BTC (așa cum este autorizat în temeiul unui acord de împrumut aplicabil), vom percepe o taxă forfetară de 2% din totalul tranzacției.",
            title2: "Taxe de tranzacționare și spread",
            content2:
                "Taxe StratTrade\n" +
                "\n" +
                "Atunci când cumpărați, vindeți sau convertiți criptomonede pe StratTrade, se percep taxe. Aceste comisioane sunt calculate în momentul plasării comenzii și pot fi influențate de factori precum metoda de plată aleasă de dvs., dimensiunea comenzii, condițiile pieței, locația jurisdicțională și alte costuri pe care le suportăm pentru a facilita tranzacția dvs.\n" +
                "\n" +
                "Înainte de a trimite tranzacția, puteți vizualiza taxele în ecranul de previzualizare a tranzacției. Vă rugăm să rețineți că taxele pot varia pentru tranzacții similare. Prin finalizarea unei comenzi, sunteți de acord să plătiți taxele StratTrade aplicabile pentru tranzacția respectivă.\n" +
                "\n" +
                "Spread\n" +
                "\n" +
                "Atunci când plasați comenzi simple de cumpărare și vânzare, StratTrade include un spread în prețul cotat. Spread-ul este, de asemenea, inclus în cursul de schimb atunci când conversia de la o criptomonedă la alta.\n" +
                "\n" +
                "Pentru a vedea spread-ul înainte de a previzualiza un ordin de cumpărare, vânzare sau conversie, pe ecranul de previzualizare:\n" +
                "\n" +
                "Comenzi de cumpărare și vânzare: atingeți sfatul de instrumente de lângă elementul liniei de preț a activului dvs. pentru a vizualiza spread-ul.\n" +
                "\n" +
                "Conversia comenzilor: apăsați pe sfatul de instrumente de lângă cursul de schimb cotat.\n" +
                "\n" +
                "Spread-ul ajută la creșterea probabilității unei tranzacții reușite și permite StratTrade să blocheze temporar prețul cotat în timpul procesării comenzii. StratTrade poate reține orice marjă excedentară dintr-o tranzacție. Vă rugăm să rețineți că spread-ul poate varia pentru tranzacții similare.\n" +
                "\n" +
                "StratTrade avansat\n" +
                "\n" +
                "Nu este inclus niciun spread pentru StratTrade Advanced deoarece interacționați direct cu cartea de comenzi.\n" +
                "\n" +
                "StratTrade One\n" +
                "\n" +
                "StratTrade One este un produs de abonament care oferă cumpărare și vânzare gratuită de criptomonede, cu anumite limitări. Membrii pot avea încă spread inclus în prețurile cotate.\n" +
                "\n" +
                "Modificări ale comisioanelor de tranzacționare și ale spread-ului\n" +
                "\n" +
                "StratTrade testează ocazional modificări ale taxelor și spread-ului. Aceste modificări pot fi implementate în diferite regiuni, active, dimensiuni ale comenzilor și tipuri de tranzacții.\n" +
                "\n" +
                "StratTrade vă arată suma totală, inclusiv spread-ul și comisioanele, de fiecare dată când previzualizați o tranzacție și ulterior în istoricul tranzacțiilor. \n" +
                "\n" +
                "Executarea tranzacțiilor\n" +
                "\n" +
                "Toate tranzacțiile din soldul dvs. primar sunt executate prin intermediul portofoliului central de comenzi limită al Bursei StratTrade sau prin licitații competitive. Puteți vizualiza registrul de comenzi, licitația, cererea și profunzimea unei piețe în timp real. Aceste registre de comenzi determină prețurile cotate pe care le vedeți în timpul tranzacționării.\n" +
                "\n" +
                "StratTrade nu are nici o plată pentru fluxul de ordine (PFOF) relații cu factorii de piață.",
            title3: "Cardul StratTrade",
            content3:
                "Cheltuielile cu cardul StratTrade nu au taxe de tranzacție. \n" +
                "\n" +
                "StratTrade include un spread în prețul de a cumpăra sau vinde criptomonede. Acest lucru ne permite să blocăm temporar un preț pentru executarea tranzacției în timp ce revizuiți detaliile tranzacției înainte de a trimite tranzacția.  \n" +
                "\n" +
                "Este posibil să vi se perceapă taxe de către un operator ATM. Vă rugăm să consultați Acordul dvs. StratTrade pentru detalii suplimentare.",
            title4: "Servicii de pariere",
            content4:
                "Nu există nici o taxă de miză sau de retragere. StratTrade primește un comision bazat pe recompensele pe care le primești de la rețea. Comisionul nostru standard este de 35% pentru ADA, ATOM, DOT, MATIC, SOL și XTZ. Pentru ADA, ATOM, DOT, SOL și XTZ, comisionul este de 26,3% pentru membrii eligibili StratTrade One. Puteți găsi rata comisionului ETH în Detalii active. Unii utilizatori pot primi oferte pentru promotii de pariere cu tarife mai mici. APY și recompensele afișate în contul dvs. reflectă întotdeauna câștigurile pe care le primiți după comisionul nostru. Puteți găsi mai multe despre comisioane în Acordul de utilizare StratTrade.",
            title5: "Recuperarea activelor",
            content5:
                "Dacă ați trimis o criptomonedă neacceptată în contul dvs. StratTrade, aceasta poate fi eligibilă pentru recuperare. Vom percepe o taxă de reţea pentru încercarea de recuperare. Pentru recuperarile cu o valoare estimată de peste 100$, vom percepe, de asemenea, o taxă de recuperare de 5% pentru suma de peste 100$. Valoarea estimată a redresării poate diferi de valoarea reală de piață a redresării.",
        },
        award1: "Cel mai bun contract pentru Broker de Diferențe din Asia în 2023",
        award2: "2022 Global Best Broker",
        award3: "Cel mai de încredere Broker în 2022",
        award4: "Cel mai de încredere brand din 2021",
        award5: "Cel mai bun broker de tehnologie financiară",
        award6: "Cel mai transparent broker pentru tranzacții",
        analysis: "Analiză",
        health: "Sănătate",
        country: "Țară",
        information: "Informații",
        help: "Ajutor",
        confirm: "Recunoaște",
        cancel: "Eliminarea",
        tip: "solicitări",
        place_select: "Vă rugăm să selectați",
        placeEnter: "Vă rugăm să introduceți",
        countryCode: "Codul țării",
        submit: "Trimite ",
        more: "Mai mult",
        deposit: "Depozit",
        real_trade: "Real Deal",
        paper_trade: "Demo Trading",
        build_paperTrade: "Creează un cont demo",
        start_trade: "Începe tranzacționarea acum",
        app_name: "StratTrade",
        success: "Succese！",
        dataSources_name: "Central de tranzacționare",
        reset: "Reproducerea",
        iknow: "Am înţeles!",
        noData: "Nu există date disponibile",
        modify: "Editează",
        pingcang: "Închide o poziție",
        all: "Full",
        tip_cardMain: "Faceți sau încărcați o fotografie a frontului cărții de identitate",
        tip_cardBack: "Faceți sau încărcați o fotografie a celui din spatele cărții de identitate",
        tip_cardMain1:
            "Faceți sau încărcați o fotografie a faței permisului de conducere",
        tip_cardBack1:
            "Faceți sau încărcați o fotografie cu spatele permisului de conducere",
        tip_cardMain2: "Luați sau Încărcați o fotografie a pașaportului dvs.",
        tip_cardBack2: "Luați sau Încărcați o fotografie a pașaportului dvs.",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Etere",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (coin)",
        adausdt: "Ada currency",
        eosusdt: "Pomelo Coin",
        dotusdt: "Boca coin",
        trxusdt: "Wave field coin",
        xmrusdt: "Monroe Coin",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Canadian and New Zealand dollars",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Brent crude oil",
        hf_GC: "New York gold",
        hf_SI: "New York silver",
        hf_HG: "Copper (US)",
        hf_NG: "U.S. Natural Gas",
        hf_CAD: "Copper (chemistry)",
        hf_AHD: "London Aluminum",
        HX_AAPL: "Pomegranate",
        HX_BABA: "Alibaba, PRC e-commerce company",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (retailer)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Germany",
        HX_IBEX: "IBEX35, Spain",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "New Zealand 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Contracte futures cu platină",
        hf_XPD: "Palladium Futures",
        hf_CT: "US Cotton",
        hf_SM: "Făină de soia americană",
        hf_BO: "U.S. soybean oil",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "European Snooker 50",
        HX_ICEXI: "ICEX, Iceland",
        HX_ASE: "ASE Athens, Greece",
        HX_OMXC20: "OMX Copenhagen 20",
        HX_OSEBX: "OSEBX, Norway",
        HX_OMXSPI: "OMXSPI, Sweden",
        HX_AMD: "Chaowei Semiconductor",
        HX_DIS: "Disney (name)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (brand)",
        HX_NVDA: "NVIDIA, computer graphics card company",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Seagate Technology",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Vă rugăm să selectați o monedă",
        currenctType6: "USDT-ERC20",
        currenctType7: "USDT-TRC20",
        currenctType8: "BTC",
        currenctType13: "ETH",
        currenctType14: "Dollar",
        currenctType15: "Hong Kong currency",
        currenctType16: "Taiwan currency",
        currenctType17: "Ringgit",
        currenctType18: "Singapore dollar",
        currenctType19: "Yen",
        currenctType20: "Euro",
        currenctType21: "Dollar A",
        currenctType22: "Rupiah",
        currenctType23: "Pound",
        currenctType24: "Thai baht",
        currenctType25: "Dollar",
        currenctType26: "VND",
        currenctType27: "Korean won",
        currenctType28: "MOP",
    },
    addnew2: {
        address: {
            account_type: "Tipul contului",
            ifsc_code: "IFSC CODE",
            title: "Adresa portofelului",
            account_number: "Numărul contului",
            add_btn: "Adaugă adresă",
            label_type: "Selectează tipul",
            place_type: "Vă rugăm să selectați tipul",
            label_number: "Cont",
            place_number: "Vă rugăm să introduceți numărul contului",
            wallet_address: "Adresa portofelului",
            bank_name: "NUMELE BANCII",
            bank_address: "Adresa băncii",
            name: "Denumire",
            payee_address: "Adresa beneficiarului",
            swift_code: "Codul rapid/rapid/BIC",
            aba: "ABA",
            branch_number: "Numele sucursalei",
            branch_code: "CODUL BRANCH",
            bank_code: "Codul bancar",
            phone: "Număr de telefon mobil",
            iban: "IBAN",
            sort_code: "Cod de sortare",
            place_inner: "Introduceți conținutul",
        },
    },
    addnew: {
        paypassTit: "Schimbă parola de retragere",
        paypassTit1: "Vă rugăm să setați parola de retragere",
        paypassTit2: "Inițializează parola cu parola de autentificare.",
        loan: "Împrumut",
        repaid: "Rambursare",
        unpaid: "Nepătit",
        loanMore: "Am nevoie de un împrumut.",
        immediateRepayment: "Rambursare imediată",
        accountType1: "Cont cripto",
        accountType2: "Contul contractului",
        accountType3: "Conturi de opțiuni",
        from: "Ascultă ",
        to: "Pentru",
        get: "Va primi",
        labelBreed: "Monedă",
        placeBreed: "Vă rugăm să selectați moneda",
        labelTransAccount: "Cont",
        labelAmount: "Suma",
        placeAmount: "Vă rugăm să introduceți suma transferului",
        transferTit: "Transfer",
        convertTit: "Convertește",
        balanceTit: "Sold estimat",
        available: "Disponibil",
        pending: "Închide cu o încuietoare",
        equivalent: "Să fie echivalent cu",
        coinTrade: "Tranzacționare cripto",
        secondContract: "Al doilea contract",
        number: "Cantități",
        labelNumber: "Cantități",
        placeNumber: "Vă rugăm să introduceți numărul de răscumpărări",
        time: "Calendar",
        loadAmount: "Valoarea preconizată a împrumutului",
        repaymentCycle: "Ciclul de rambursare a creditelor",
        dailyRate: "Rata zilnică",
        repaymentMethod: "Metoda rambursării",
        loanTip: "Credit credit credit (vă rugăm să vă asigurați că imaginea este clar vizibilă)",
        loanTip1: "Încărcați certificatul de proprietate",
        loanTip2: "Dovada venitului (ocuparea forței de muncă)",
        loanTip3: "Detalii privind extrasele bancare",
        loanTip4: "Încărcați fotografia din față a cardului dvs. de identitate",
        interest: "Interes (dorința știe despre sth)",
        repaymentMethod1: "Datorii rambursării în sumă forfetară",
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Mărfuri",
        product_number: "Indici",
        product_stock: "Stoc",
        product_coin: "Crypto",
        billTypes: [
            { v: 101, name: "Reîncărcare" },
            { v: 102, name: "Depozit" },
            { v: 103, name: "Congelat" },
            { v: 104, name: "Deblochează" },
            { v: 105, name: "Depozit" },
            { v: 106, name: "Depozit" },
            { v: 201, name: "Retragere congelare" },
            { v: 202, name: "Retragerea fondurilor" },
            { v: 203, name: "Retragerea cu succes" },
            { v: 204, name: "Nerespectarea fondurilor" },
            { v: 205, name: "Taxa de retragere" },
            { v: 206, name: "Transfer afară" },
            { v: 207, name: "Schimbă la" },
            { v: 208, name: "Transfer de monede" },
            { v: 209, name: "Transfer de monede" },
            { v: 301, name: "Taxa contractuală" },
            { v: 302, name: "Venituri contractuale" },
            { v: 303, name: "Pierderea contractului" },
            { v: 304, name: "Marja contractului" },
            { v: 305, name: "Returnarea marjei contractuale" },
            { v: 311, name: "Opțiuni de cumpărare" },
            { v: 312, name: "Venituri din opțiuni" },
            { v: 313, name: "Opțiune Returnează" },
            { v: 314, name: "Taxa de gestionare a opțiunilor" },
            { v: 315, name: "Monedă Cumpără Freeze" },
            { v: 316, name: "Deducerea cumpărării de monede" },
            { v: 317, name: "Returnări de cumpărare de monede" },
            { v: 318, name: "Cumpără monedă în cont" },
            { v: 319, name: "Cumpără monedă în cont" },
            { v: 320, name: "Înghețați vânzarea de monede" },
            { v: 321, name: "Deducerea vânzărilor de monede" },
            { v: 322, name: "Returnări de vânzare a monedelor" },
            { v: 323, name: "Monedă de vânzare în cont" },
            { v: 324, name: "Monedă de vânzare în cont" },
            { v: 325, name: "Taxa de manipulare a monedelor" },
            { v: 401, name: "Minerii se alătură" },
            { v: 402, name: "Miner returnează" },
            { v: 403, name: "Venituri miniere" },
            { v: 404, name: "Ieșire minieră" },
            { v: 405, name: "Taxa de ieșire din minerit" },
            { v: 411, name: "Venituri din împrumuturi" },
            { v: 412, name: "Taxa de împrumut" },
            { v: 413, name: "Rambursarea cu succes" },
        ],
    },
    message: {
        logout: "Deconectați-vă",
        register_success: "Înregistrarea cu succes",
        place_inner_personal: "Vă rugăm să introduceți mai întâi informațiile dvs. personale",
        submit_success: "Trimis cu succes！",
        copy_success: "Copiază succes！",
        copy_error: "Eşec de reproducere！",
        modify_success: "Modificat cu succes",
        no_balance: "Sold insuficient pentru a acoperi depozitul de garanție",
        palce_password: "Vă rugăm să introduceți parola",
        trade_success: "Tranzacție reușită",
        placeEnterAmount: "Vă rugăm să introduceți suma",
        tipTit1: "Sunteți sigur că doriți să plătiți pentru această comandă?",
    },
    web: {
        account: {
            menu1: "Informații despre cont",
            menu2: "Activele mele",
            menu3: "Rapoarte de cont",
            menu4: "Securitatea contului",
            menu5: "Notificări",
            menu6: "Centre de activitate",
            menu7: "Portofelul meu",
            menu8: "Retragere",
            walletAdd: "Adaugă portofel nou",
            report: {
                history_tit: "Raportul istoricului tranzacțiilor",
                stream_tit: "Raportul fluxului de fonduri",
                desc: "Vă rugăm să selectați intervalul de date corespunzător pentru a exporta raportul contului. (Veți avea acces la informațiile contului pentru ultimii 1 an)",
                select_date: "Selectează intervalul de date",
                week: "Săptămâna trecută",
                month: "Aproximativ o lună",
                threeMonth: "Trei luni",
                sixMonth: "Şase luni",
                time: "Calendar",
                view_report: "Afișează raportul",
                email_send: "Trimite prin e-mail",
                dialog_tit: "Rapoarte de cont",
                dialog_p1:
                    "StratTrade Holding Ltd este înregistrată în Insulele Cayman (număr SIB: 1612446) și procesează tranzacțiile dvs. ca entitate comercială.",
                tit2: "Raportul istoricului tranzacțiilor Mitade",
                tit4: "Raportul privind fluxul de fonduri Mitade",
                tit3: "Informații despre cont",
                label1: "Numele contului",
                label2: "ID cont",
                label3: "Moneda contului",
                label4: "Timpul raportării",
                label5: "Perioada de raportare",
                label6: "Fluxul de numerar",
                empty: "Nu aveţi tranzacţii închise.",
                email_des: "Raportul va fi trimis la adresa dvs. de e-mail",
                tab1: "Raportare",
                tab2: "Declarație zilnică",
                tab3: "Declarație lunară",
            },
            account: {
                tit1: "Bun venit la StratTrade",
                tit2: "Puteți începe să faceți depozite și să tranzacționați în mediul live după finalizarea verificării ID-ului.",
                p1: "Deschide cont live",
                p2: "Verificarea identității",
                btn1: "Verificarea ID-ului",
                tit3: "Informații personale",
                label_name: "Nume",
                label_email: "E-mail",
                label_address: "Adresa rezidențială",
                tit4: "Informații despre cont",
                label_account: "Numărul contului",
                label_currency: "Moneda de bază",
                label_company: "Entitate de serviciu curentă",
                company_tip:
                    "StratTrade Holding Ltd of Companies este licențiată și autorizată de Autoritatea Monetară a Insulelor Cayman (CIMA).",
                tit5: "Deschide cont live",
                tit6: "Profil complet",
                tit7: "Completați informațiile de bază",
                tit8: "Evaluarea riscurilor",
                tit9: "Evaluați capacitatea de suportare a riscurilor",
                tit10: "Verificarea identității",
                tit11:
                    "Completați verificarea ID-ului în conformitate cu cerințele de reglementare",
                p3: "Vă rugăm să selectați unul dintre următoarele documente justificative pentru verificarea ID-ului",
                type1: "ID național",
                type2: "Permis de conducere",
                type3: "Pașaport",
                p4: "Confirm că toate informațiile și documentele furnizate sunt complete, adevărate și exacte. Recunosc pentru orice modificare a informațiilor furnizate, voi notifica Mitrade în termen de 30 de zile.",
                next: "Pasul următor",
                btn2: "Certificat",
                modify_personal_tit: "Editează informațiile dumneavoastră",
            },
            activity: {
                tit: "Centre de activitate",
                desc: "Puteți participa la activități pentru a primi recompense pentru a vă spori veniturile din investiții",
            },
            capital: {
                real_trade: "Real Deal",
                label_nowAccount: "Contul curent",
                label_tradeAccount: "Cont de tranzacționare",
                label_currency: "Monedă de bază",
                deposit_withdraw_record: "Înregistrări de depunere și retragere",
                capital_flow_details: "Detalii fluxului de fonduri",
            },
            notification: {
                tit: "Configurări notificări",
                tit1: "Deschide canale de notificare",
                email: "Adresa de e-mail",
                message: "Mesaje text",
                push: "Împinge",
                verify: "Validare (o teorie)",
                verified: "Validare (o teorie)",
                p1: "Vă rugăm să deschideți / verificați următoarele canale de notificare pentru a ține evidența știrilor importante!",
                type1: "Marketing",
                tit2: "Notificarea activităților de comercializare",
                desc2:
                    "Informații speciale ale platformei, activități de operare și alte știri",
                tit3: "Instrucţiuni de utilizare",
                desc3: "Materiale bogate și specializate de cunoștințe",
            },
            security: {
                tit: "Criptografică",
                isSet: "Configurat",
                set: "Du-te şi pregăteşte-te.",
                verify: "Validare (o teorie)",
                verified: "Validat",
                tit1: "Certificare de siguranță",
                email: "Adresa de e-mail",
                phone: "Număr de telefon mobil",
                phone_tit: "Legarea numărului de telefon mobil",
                password_tit: "Schimbă parola",
            },
        },
        market: {
            hot: "Popular",
            prev: "Pagina precedentă",
            next: "Pagina următoare",
            line: "Partajarea timpului",
            main_subplot: "Indicatori principali și adjuncți ai graficii",
            main: "Indicatorul principal al graficului",
            subplot: "Indicator grafic",
            go_trade: "Treci la pagina tranzacției",
        },
        news: {
            tip: "Disclaimer: Informațiile de mai sus sunt informații generale doar cu titlu de referință și nu trebuie utilizate ca bază pentru deciziile de tranzacționare.",
        },
        trade: {
            state1: "Exploatații",
            state2: "Ordin în așteptare",
            state3: "Istorice",
            state: "Mod",
            state4: "Poziție închisă",
            state5: "Anulat",
            type: "Tipologie",
            type1: "Full",
            type2: "Vinde",
            type3: "Cumpără",
            left_label1: "Self-service",
            left_label2: "Vizualizate recent",
            left_type1: "Modul coloană compactă",
            left_type2: "Model colonar liber",
            left_type3: "Arată diagrama",
            all: "Full",
            right_bp: "Recolta de ieri",
            right_sp: "Zilele moderne",
            right_height: "Supreme",
            right_low: "Cel mai mic",
            remind_tit: "Alerte noi",
            remindList: "Lista memento-urilor",
            remind_btn: "Alerte noi",
            right_tab1: "Afaceri",
            right_tab2: "Particulare",
            right_sell: "Vinde",
            right_buy: "Cumpără",
            right_type: "Tipologie",
            right_type1: "Lista pieței",
            right_type2: "Ordin în așteptare",
            right_priceMoney: "Prețul comenzii în așteptare",
            right_pointerNumber: "Număr (loturi)",
            right_lever: "Pry bar",
            right_balance: "Disponibil",
            right_stop_profit: "Ia profit",
            right_stop_loss: "Stop-loss",
            right_profit_loss: "P/L",
            now_order: "Comandă acum",
            confirm_order: "Confirmarea comenzii",
            right_tit1: "Strategii de tranzacționare",
            right_rinei: "În câteva zile",
            right_short: "Pe termen scurt",
            right_middle: "Perioada medie",
            right_time: "Timp de eliberare",
            right_tit2: "Strategie alternativă",
            right_tit3: "Revizuire tehnică",
            right_tit4: "Digital",
            right_tit5: "Starea de spirit de tranzacționare",
            right_label_sell: "Vânzător (de bunuri)",
            right_label_buy: "Cumpărător",
            right_tip:
                "Numai în scop informativ, nu o poziție a diviziei",
            right_tip2: "Actualizat la fiecare 15 minute",
            right_tit6: "Variaţia preţului",
            minute: "Minute",
            today: "În prezent",
            right_tit7: "Intervalul de schimbare a prețului",
            now_price: "Prețuri curente",
            right_low_price: "Cel mai mic preț",
            right_height_price: "Cel mai mare preț",
            right_tit8: "Informații privind contractul",
            right_tit9: "AUD/CHF",
            right_label1: "Numărul de tranzacții unice",
            right_label2: "Pârghie maximă",
            right_label3: "Dimensiunea contractului",
            right_label4: "Numărul total maxim de poziții",
            right_label5: "Răspândire plutitoare",
            right_label6: "taxă peste noapte",
            hand: "Mână",
            right_collection_time: "Timpul colectării",
            right_tip3: "Baza comisioanelor: din dimensiunea totală a tranzacției",
            right_label7: "Raport marjă",
            right_label8: "Rata marjei de menținere",
            right_label9: "Orele de tranzacționare",
            right_label10: "Sesiunea curentă de tranzacționare",
            right_label11: "Următoarea sesiune de tranzacționare",
        },
    },
    home: {
        banner_p1: "",
        banner_p2: "Comerț ",
        banner_p3: "Forex, mărfuri,",
        banner_p4: "Indici, acțiuni și altele",
        banner_p5:
            "Puteți explora cele mai tari piețe financiare din lume. StratTrade oferă 0 comisioane, spread-uri reduse extrem de avantajoase și configurații flexibile de levier.",
        hot: "Popular",
        tit1: "Tranzacții mai prietenoase",
        p1: "Interfață simplă și intuitivă pentru o operare ușoară",
        p2: "Suport web, APP mobil și desktop, tranzacționare la îndemână",
        p3: "Instrumente de management al riscurilor, cum ar fi stop loss/trailing stop",
        p4: "Grafice tehnice multiple și calendare financiare, știri în timp real",
        p5: "Notificări prin e-mail, SMS și push în timp real",
        p6: "Îmbunătățirea continuă pentru o experiență de tranzacționare mai bună",
        btn1: "Explorează platforma noastră",
        row3_tit: "Comerț liber oricând, oriunde",
        row3_tip: "Suport web, APP mobil și desktop, tranzacționare la îndemână",
        row3_tit2: "Scanează codul de descărcat",
        row3_tit3: "Desktop",
        row3_tit4: "Mobil",
        regular_tit: "Platforma internaţională de încredere",
        regular_tip:
            "Ne angajăm să oferim clienților noștri un mediu de tranzacționare sigur și responsabil.",
        regular_tit1: "Reglementate de un organism autoritar",
        regular_p1:
            "Reglementarea și autorizarea autorității industriale, câștigă încrederea clienților globali",
        regular_tit2: "Protejarea securității fondurilor",
        regular_p2:
            "Depozitele clienților cu amănuntul sunt separate în conturi fiduciare, după cum este necesar, în conformitate cu cerințele de reglementare",
        regular_tit3: "Protecția echilibrului negativ",
        regular_p3:
            "Oferiți-vă protecția soldului negativ al contului, soldul negativ în timp util pentru a șterge zero, astfel încât pierderea dvs. să nu depășească valoarea intrării, liniștea minții de tranzacționare",
        regular_tit4: "5 * 24 de ore de serviciu pentru clienți",
        regular_p4:
            "Echipa sinceră și profesională de servicii pentru clienți, asistență online 24 de ore, fericită să rezolve orice problemă aveți!",
        service_tit: "Mai multe servicii pentru a vă ajuta",
        service_tit1: "Strategii de tranzacționare",
        service_p1:
            "Strategii de tranzacționare în timp real pentru a vă ajuta să înțelegeți cele mai recente tendințe ale pieței, să înțelegeți mai bine momentul tranzacționării.",
        service_tit2: "Cunoștințe de tranzacționare",
        service_p2:
            "Învață să tranzacționezi gratuit cu StratTrade și îmbunătățește-ți abilitățile de tranzacționare.",
        service_tit3: "Gestionarea riscurilor",
        service_p3:
            "Aflați mai multe despre instrumentele gratuite de management al riscurilor pe care StratTrade le oferă pentru a vă proteja mai bine activele.",
        step_tit: "Ușor și convenabil pentru a deschide un cont",
        step_tip: "Trei pași simpli pentru a deschide un cont în câteva minute",
        step_tit1: "Înscriere",
        step_p1: "Completați informațiile și trimiteți cererea dumneavoastră",
        step_tit2: "Depozit",
        step_p2: "Depunerea rapidă a fondurilor printr-o varietate de metode",
        step_tit3: "Începerea tranzacționării",
        step_p3: "Descoperiți oportunitățile de tranzacționare și plasați comenzi rapid",
        award_tit: "Realizările noastre",
        award_p1:
            "Am urmărit întotdeauna excelența, străduind excelență și ne-am angajat să oferim clienților noștri servicii de tranzacții de calitate.",
        award_p2:
            "Brandul StratTrade este onorat să primească un premiu de la o organizație prestigioasă din industrie, recunoscând eforturile constante ale echipei și angajamentul față de clienții săi.",
        row7_tit: "Informații financiare globale în timp real",
        row7_tit1: "Știri în timp real",
        exchange_tit1: "Formarea industriei opţiunilor criptate.",
        exchange_tit2: "Din 2016.",
        exchange_subtit: "StratTrade Exchange",
        exchange_card1_tit: "Pentru începători.",
        exchange_card1_p:
            "Porniți-vă abilitățile de tranzacționare cu cursul nostru gratuit de opțiuni. Experimentați pe platforma noastră de testare fără niciun risc. Utilizați asistența noastră 24/7 pentru orice întrebări. Călătoria ta derivate începe aici..",
        exchange_card2_tit: "Comercianți experimentați.",
        exchange_card2_p:
            "Cufundați-vă în mediul nostru de tranzacționare de nivel instituțional. Utilizați cele mai avansate instrumente din industrie la cea mai lichidă piață de opțiuni din lume.",
        exchange_card3_tit: "Pentru instituții.",
        exchange_card3_p:
            "Experimentați de ce cele mai proeminente instituții au preferat StratTrade din 2016. Colo Services în LD4, Portfolio Margin Model, Block Tranzacții, Combo-uri, cea mai bună lichiditate, Multicast și multe altele.",
    },
    learn: {
        education: {},
    },
    login: {
        click_download: "Click pentru a descărca",
        p1: "Comerț oricând, oriunde",
        p2: "Tranzacționați mai rapid și mai inteligent",
        p3: "0 comercializarea comisioanelor",
        p4: "Cea mai tare investiţie din lume",
        renderDom1: "Schimb valutar",
        renderDom2: "Bursa americană",
        renderDom3: "Platină",
        renderDom4: "Petrol brut",
        renderDom5: "Indici",
        renderDom6: "Criptomonedă",
        login: "Autentifică-te",
        elail: "Email",
        phone: "Număr de telefon",
        no_account: "Fără cont？",
        go_register: "Du-te la înregistrare",
        register: "Înscriere",
        now_register: "Înregistrează-te acum",
        have_account: "Am deja un cont？",
        go_login: "Dă-i drumul şi autentifică-te..",
    },
    about: {
        serviceFee: "Taxa de serviciu",
        introduction: "Introducere platformă",
        trade_platform: "Platformă de tranzacționare",
        complaint_process: "Procesul de reclamații",
        page: "Pagina web",
        place_search: "Caută ceea ce vrei să știi",
        no_answer: "Nu găsesc răspunsul de care ai nevoie?",
        contact_us: "Contactează-ne",
        awards: {
            h1: "Premii și onoruri",
            p1: "În căutarea noastră de excelență și dedicație pentru a oferi clienților noștri servicii de tranzacționare de calitate, brandul StratTrade este onorat să primească un premiu de la o organizație prestigioasă din industrie, recunoscând eforturile continue și angajamentul echipei față de clienții noștri.",
            p2: "Sau experimentați imediat platforma noastră de tranzacționare online premiată!",
        },
        charges: {
            h1: "Taxe și taxe",
            p1: "Ca parte a eforturilor noastre de a îmbunătăți experiența clienților, StratTrade dorește să ofere clienților noștri un serviciu de tranzacționare mai rentabil. Ne angajăm să fim deschiși și transparenți cu privire la taxele și taxele noastre, fără taxe ascunse. Principalele costuri de tranzacție pentru clienții noștri sunt spread-ul bid/ask și dobânda poziției overnight.",
            h2: "În primul rând, atunci când tranzacționați cu StratTrade vă puteți bucura de",
            li1: "Cotație instantanee de produs",
            li2: "Indicatori tehnici specializați",
            li3: "Analiza exclusivă a pieței",
            free: "Gratuit (gratuit)",
            view: "Uită-te în sus",
            h3: "Spread licitație-cerere",
            p2: "StratTrade percepe un spread bid/ask ca compensație pentru serviciile sale. Această taxă se reflectă în cotația de produs și plătiți spread-ul atunci când deschideți o poziție. Deoarece taxa spread nu este fixă, vă recomandăm să accesați pagina de ofertă specifică a produsului sau să vă conectați la",
            h4: "Taxe de depunere și retragere",
            p3: "Pentru a minimiza costurile pentru clienții noștri, nu vă taxăm pentru accesarea fondurilor în majoritatea cazurilor. Cu toate acestea, o companie terță sau o bancă vă poate percepe o taxă pentru efectuarea unui depozit sau retragere, cum ar fi o taxă bancară de tranzit.",
            p4: "Puteți solicita specialiștilor noștri de servicii pentru clienți informații despre taxele care trebuie efectuate.",
            p5: "De exemplu, prin tranzacții internaționale cu carduri de credit, transferuri bancare în / ieșire sau tranzacții în monede neacceptate (conversie Forex), etc..",
            h5: "Costuri de poziție overnight",
            p6: "Dacă dețineți încă o poziție la ora 22:00 GMT (22:00 GMT), vi se va percepe / depune o taxă peste noapte. Solicitările privind tarifele de noapte pentru fiecare produs pot fi făcute pe pagina de ofertă specifică a produsului sau prin conectarea la",
            h6: "Alte cheltuieli",
            p7: "Fără alte taxe. Politica noastră de taxe este complet transparentă și orice articol care necesită o taxă va fi anunțat și comunicat în avans, astfel încât nu este nevoie să vă faceți griji cu privire la taxele ascunse.",
        },
        contact: {
            h1: "Contactează-ne",
            address: "Adresă",
            custom_email: "Email de contact client",
            business_email: "Email privind cooperarea întreprinderilor",
            h2: "Scrie-ne.",
            p1: "Dacă aveți întrebări despre serviciile noastre, vă rugăm să completați formularul de mai jos pentru a trimite întrebarea dumneavoastră și unul dintre specialiștii noștri de servicii pentru clienți vă va răspunde cât mai curând posibil. În plus, pentru a vă înscrie la strategiile zilnice de tranzacționare oferite de parteneriatul nostru cu Trading Central, vă rugăm să selectați 'Abonare la Analiza zilnică a Pieței' în tipul formularului de mai jos și să completați versiunea lingvistică pe care doriți să o primiți și vă vom înregistra. * Serviciul de abonare este disponibil numai pentru clienții reali StratTrade existenți.",
            h3: "Representation procedures",
            p2: "Toate reclamațiile vom avea o echipă specializată pentru a investiga și soluționa, detalii pot fi trimise la",
        },
        faq: {
            h1: "Probleme comune",
            tit1: "Care este rostul unui cont demo？",
            tit2: "Cum să deschizi un cont real？",
            tit3: "Cum se creează o comandă de piață？",
            tit4: "De ce nu pot face o comandă？",
            p1: "Conturile demo sunt în mare parte identice cu conturile reale în ceea ce privește interfața de tranzacționare, datele și operațiunile. Conturile demo conțin fonduri virtuale de 50.000 USD și sunt concepute pentru a permite clienților să se familiarizeze cu caracteristicile platformei prin operațiuni simulate, fără a-și risca fondurile.",
            p2: "Pentru a deschide un cont live, urmați pașii de mai jos:",
            p3: "1. Introduceți pagina de înregistrare StratTrade și urmați instrucțiunile: înregistrați-vă numărul de e-mail/telefon mobil, setați parola de autentificare și trimiteți confirmarea pentru a crea automat un [Cont Demo].",
            p4: "2. Pe pagina Cont Demo, selectați 'Comutați la Cont Real' și urmați procedura pentru a deschide un 'Cont Real'.",
            p5: "Înainte de a deschide un cont live, vă recomandăm să citiți și să înțelegeți informațiile legale asociate tranzacționării.",
            p6: "Vă rugăm să rețineți că puteți depune în contul dvs. live numai după finalizarea verificării identității.",
            p7: "Faceți clic pe 'Tranzacționare' (Tranzacționare) vor afișa toate produsele disponibile pentru tranzacționare pe platformă, puteți introduce codul sau numele produsului în caseta de introducere din dreapta pentru a-l căuta, selectați un produs și faceți clic pe 'Vând/Cumpără' (Vând/Cumpără) pentru a afișa fereastra de tranzacționare, în care puteți vedea prețul curent și marja estimată necesară. Puteți ajusta manual numărul de tranzacții care urmează să fie deschise, setați un stop loss și luați profit pentru controlul riscului, apoi faceți clic pe \"Vând/Cumpără\" pentru a finaliza deschiderea poziției. Vă rugăm să rețineți că prețurile tuturor produselor financiare fluctuează și se actualizează cu piața în orice moment, iar piața s-ar putea schimba înainte de a apăsa butonul BUY/SELL.",
            p8: "În primul rând, vă rugăm să verificați dacă conexiunea la Internet este normală. Atunci când tranzacționează varietăți de piețe temporar cotațiile închise sau piețele închise pot cauza incapacitatea de a plasa comenzi. Pentru detalii, vă rugăm să contactați serviciul nostru online pentru asistență suplimentară.",
        },
        fundSecurity: {
            h1: "Garanția financiară",
            p1: "StratTrade este autorizată și reglementată de organisme de reglementare autoritare. Conformitatea este în centrul a tot ceea ce facem și al culturii noastre. Siguranța fondurilor dumneavoastră este esențială și toate depozitele clienților sunt păstrate în locații separate.",
            p2: "Depozitele clienților cu amănuntul sunt păstrate separat în conturi fiduciare, după caz, în conformitate cu cerințele de reglementare",
            p3: "StratTrade nu utilizează fondurile clienților pentru alte activități comerciale decât cele permise de legi și reglementări.",
            p4: "StratTrade nu va specula pe nici o piață",
            p5: "Audit independent efectuat de o societate de contabilitate terță",
        },
        help: {
            h1: "Bine ați venit la Centrul de ajutor StratTrade!",
        },
        media: {
            h1: "Centru media",
            p1: "Vizualizați informații, comunicate de presă, caracteristici media, anunțuri și alte informații legate de brandul StratTrade.",
        },
        mitrade: {
            h1: "Despre StratTrade",
            view_certificate: "(Metode de identificare)",
            p1: "StratTrade este o companie de tehnologie financiară autorizată și reglementată de instituții autoritare, concentrându-se pe oferirea investitorilor o experiență de tranzacționare simplă și convenabilă. Platforma inovatoare de tranzacționare diversificată StratTrade a câștigat în mod repetat premii precum cea mai bună platformă de tranzacționare mobilă și cea mai inovatoare brokeraj.",
            p2: "Prin intermediul platformei StratTrade, puteți investi și tranzacționa într-o gamă mai largă de piețe financiare internaționale, acoperind acțiuni, indici, mărfuri, schimb valutar și sute de alte varietăți populare. Nu numai că se limitează la platformele de tranzacționare mobile și web ușor de utilizat, StratTrade vă oferă, de asemenea, costuri competitive de tranzacționare, executare rapidă a tranzacțiilor, servicii excelente pentru clienți și o multitudine de asistență informațională, ajutându-vă să profitați în timp util de investiții și oportunități de tranzacționare.",
            p3: "StratTrade este strict reglementat de autoritatea industrială și de mai multe agenții. StratTrade International este autorizată și reglementată de Comisia Serviciilor Financiare (FSC) din Mauritius sub numărul de licență GB20025791. Pentru detalii despre licența Mauritius, puteți accesa site-ul oficial al FSC la https://www .fscmauritius.org",
            p4: "MStratTrade Global este licențiată de Comisia Australiană pentru Valori Mobiliare și Investiții (ASIC) sub Licența Serviciilor Financiare (AFSL 398528). Pentru mai multe informații despre licențele australiene, vă rugăm să vizitați site-ul ASIC la www.asic.gov.au.",
            p5: "Itrade Holding este autorizată și reglementată de Autoritatea Monetară a Insulelor Cayman (CIMA) sub licența SIB nr. 1612446. Pentru mai multe informații despre licența Caymanian, vă rugăm să vizitați site-ul oficial CIMA la www.cima.ky.",
            p6: "Toate operațiunile de afaceri ale StratTrade se desfășoară sub supraveghere strictă și în conformitate cu toate reglementările.",
            h2: "Contextul StratTrade",
            p7: "StratTrade a fost fondată în Melbourne, Australia de către o echipă de profesioniști în tranzacționare financiară cu experiență și cunoștință, cu viziunea de a face tranzacționarea mai ușoară și mai ușor de utilizat!",
            p8: "OStrategia noastră holistică și modelul nostru de afaceri ne permit să avem o observare globală și aprofundată și cunoaștere a dezvoltării industriei și a cererii pieței, astfel încât să putem aloca resursele într-o manieră mai direcționată, să inovăm continuu tehnologia și să optimizăm eficiența și să continuăm să oferim clienților noștri o experiență de tranzacționare mai convenabilă și prietenoasă. ",
            h3: "De ce Strattrade",
            why1: "Condiții de tranzacționare cu prag scăzut",
            why2: "Reglementate de autoritățile din industrie",
            why3: "Platformă de tranzacționare simplă și intuitivă",
            why4: "Nivel ridicat de sprijin online",
            why5: "Costuri competitive ale tranzacțiilor",
            why6: "Protecția echilibrului negativ",
            h4: "Premii și onoruri",
            p9: "Am urmărit întotdeauna excelența, străduind excelență și ne-am angajat să oferim clienților noștri servicii de tranzacții de calitate.",
            p10: "Brandul StratTrade este onorat să primească un premiu de la o organizație prestigioasă din industrie, recunoscând eforturile constante ale echipei și angajamentul față de clienții săi.",
            new_add: "Noi utilizatori la nivel mondial",
            ol1: "Cel mai bun furnizor de știri și analize",
            ol2: "Cel mai bun Broker Forex în Asia",
            ol3: "Australian Forex Customer Satisfaction Awards",
            ol4: "Cea mai bună aplicație mobilă",
            ol5: "Brokerul australian cu cea mai rapidă creștere",
            h5: "Filozofia de operare a StratTrade",
            tip_tit1: "Obligații",
            tip_tit2: "Simplu, dar frumos",
            tip_tit3: "Deschis (nesecretos)",
            tip_tit4: "Blaze noi trasee",
            tip_p1:
                "Toate tranzacționarea implică riscuri, iar StratTrade a luat măsuri suplimentare pentru a oferi clienților săi un mediu de tranzacționare responsabil. StratTrade este strict reglementat de autoritățile din industrie, mai multe organizații și funcționează sub standarde de securitate și stabilitate la nivel înalt. Platforma noastră continuă să ofere o serie de instrumente de tranzacționare responsabile, inclusiv managementul riscurilor.",
            tip_p2:
                "Simplitatea este frumusețe, detaliile fac excelență, StratTrade este dedicat ușurinței de utilizare și experienței utilizatorului în fiecare detaliu. Continuăm să optimizăm platforma și serviciile noastre în speranța că toată lumea, de la începători la investitori experimentați, va putea profita de oportunitățile de tranzacționare prin intermediul platformei StratTrade.",
            tip_p3:
                "Pentru a permite clienților noștri să beneficieze pe deplin de un mediu de tranzacționare echitabil și eficient, StratTrade urmează un sistem riguros și transparent de autoreglementare, oferă informații cuprinzătoare și transparente privind produsele platformei, mediul de prețuri și date, iar toate taxele sunt dezvăluite în mod clar și deschis pentru a asigura sinceritatea și transparența completă a întregului proces de servicii..",
            tip_p4:
                "StratTrade Credem cu tărie că progresul tehnologic, inovația financiară și serviciile mai largi sunt forțele motrice ale creșterii economice și numai atunci putem crea și împărtăși continuu valoarea. Din acest motiv, încurajăm gândirea deschisă, promovăm continuu inovația și urmărim să devenim inovatori și lideri în tehnologia financiară.",
            row6_tit1: "Misiunea noastră",
            row6_tit2: "Viziunea noastră",
            row6_tit3: "Oportunități de muncă",
            row6_p1:
                "Asigurarea unui mediu comercial echitabil, eficient și transparent, care să nu fie limitat de spațiu, timp și piață pentru mai mulți oameni care doresc să facă comerț și să conducă industria în inovația financiară și tehnologică.",
            row6_p2:
                "Credem că progresul tehnologic, inovația financiară și serviciile diversificate sunt forțele care ne conduc creșterea și ne permit să continuăm să creăm și să împărtășim valoarea în societate..",
            row6_p3:
                "În acest scop, încurajăm deschiderea minții și promovăm inovarea, având ca scop să devină, prin creștere continuă și progres, un",
            row6_p4: "Cea mai importantă companie de schimb valutar din lume",
            row6_p5:
                "Suntem o echipă globală în 7 țări și cu o echipă în creștere rapidă, suntem întotdeauna în căutarea de profesioniști care să ni se alăture.!",
            row6_p6:
                "Vă rugăm să ne trimiteți CV-ul dvs. actualizat și de ce doriți să lucrați cu noi:",
            row6_p7: "Alternativ, puteţi afla mai multe despre",
            row6_p8: "Poate",
            row6_p9: "Vezi posturile noastre libere.",
            row6_p10:
                "StratTrade protejează strict securitatea informațiilor dvs. personale. StratTrade nu vă va cere informații financiare în timpul procesului de aplicare a unui loc de muncă. Nu avem nevoie de informații financiare, carduri de credit, conturi bancare sau plată de orice fel pentru recrutarea noastră.",
            row6_p11:
                "Dacă suspectați că o poziție este frauduloasă, vă rugăm să ne contactați prin intermediul",
            row6_p12:
                "Contactează Departamentul de Resurse Umane al StratTrade. Dacă confirmați că ați fost înșelat de o postare falsă de locuri de muncă, vă rugăm să contactați departamentul local al organizației relevante.",
        },
    },
    product: {
        row2: {
            tit: "Comercializarea soiurilor",
            sell: "Vinde",
            buy: "Cumpără",
            trade: "Afaceri",
            more_product: "Mai multe produse comerciale",
        },
        row3: {
            tit: "Suportă tranzacții multiple de dispozitive",
            tit1: "Suportă tranzacții multiple de dispozitive",
            p1: "StratTrade vă oferă platforme de tranzacționare bazate pe web, mobile și desktop. Puteți tranzacționa oricând, oriunde.",
            download: "Descărcare",
        },
        row4: {
            tit: "Selectează StratTrade",
            tit1: "Platformă simplă și intuitivă",
            p1: "Integrarea cotațiilor, tranzacționare, gestionarea conturilor, informații, gestionarea riscurilor, funcții puternice, pentru a obține o experiență mai bună a utilizatorului",
            tit2: "Costuri competitive ale tranzacțiilor",
            p2: "0 comisioane de tranzacționare, spread-uri scăzute foarte competitive și transparente, SWAP scăzute, astfel încât să vă puteți bucura de tranzacționare low-cost!",
            tit3: "Condiții de tranzacționare cu prag scăzut",
            p3: "Dimensiunea minimă a lotului pentru fiecare tranzacție este de până la 0,01 lot și puteți deschide o poziție cu o marjă scăzută.",
            tit4: "Reglementate de autoritate",
            p4: "Autorizate și reglementate de autoritățile din industrie, toate depozitele clienților sunt stocate separat și separate pentru a proteja siguranța activelor clienților",
            tit5: "Protecția echilibrului negativ",
            p5: "Contul dvs. nu va pierde niciodată mai mult decât principalul său în orice condiție de piață, iar soldurile negative vor fi compensate în timp util pentru a vă îmbunătăți capacitatea de gestionare a riscurilor.",
            tit6: "Nivel ridicat de sprijin online",
            p6: "Oferiți un răspuns rapid de serviciu pentru clienți online, va oferi un serviciu mai bun prin eforturile neîncetat ale echipei profesionale",
        },
        row5: {
            tit: "Probleme conexe",
        },
        commodities: {
            title: "Comerțul cu mărfuri",
            p: "Mărfurile precum metalele prețioase și energia sunt considerate a fi o parte importantă a investițiilor diversificate, deoarece sunt considerate atât rezistente la inflație, cât și au posibilitatea de a adăuga valoare investiției. Printre acestea, aurul, argintul și petrolul sunt mărfuri tranzacționate în volume uriașe, iar prețurile lor deschise și transparente, precum și lichiditatea ridicată au fost salutate pe scară largă de investitori. Prețurile mărfurilor fluctuează semnificativ din cauza unor factori precum cererea și oferta, situațiile economice și de politică și monede, oferind oportunități de rentabilitate a riscului.",
        },
        forex: {
            title: "Tranzacționarea valutară",
            p: "Tranzacționarea Forex este conversia unei monede în alta, iar piața valutară este cea mai puternic tranzacționată piață financiară din lume. Piața valutară are un număr mare de participanți care tranzacționează prin ea pentru a efectua plăți, pentru a acoperi riscul de mișcări valutare sau pentru a realiza un profit. Tranzacționarea Forex are loc printr-o rețea de bănci, instituții și comercianți individuali din întreaga lume, cu fluctuații de preț 24 de ore pe zi, 5 zile pe săptămână, oferind oportunități de risc-recompensare.",
        },
        indices: {
            title: "Indici",
            p: "Indicele acțiunilor este o valoare statistică care reprezintă un anumit tip de acțiune caracteristică la o anumită bursă și este un indicator al nivelului general al prețurilor și al mișcării unui anumit tip de acțiune constitutiv pe piață. Tranzacționarea indicilor de acțiuni care reflectă oportunitățile de investiții pe piața sau sectorul general poate reduce riscul investițiilor în acțiuni individuale. Cei mai populari indici din SUA, Europa, Asia și Australia, cum ar fi Australia 200, U.S. Tech 100, Hong Kong 50, Germania 30, etc., oferă oportunități diferite pe piețele de valori globale.",
        },
        shares: {
            title: "Tranzacționarea acțiunilor",
            p: "O acțiune este o valoare tranzacționabilă emisă la o bursă. Pentru a strânge capital, societățile distribuie proprietatea companiei prin intermediul burselor listate folosind certificatele. Numai în 2019, tranzacționarea globală de acțiuni a totalizat peste 60 trilioane de dolari, ceea ce este atât de mare și lichid încât rămâne unul dintre cele mai populare instrumente financiare în ochii investitorilor din întreaga lume, chiar și după mulți ani. Pe platforma StratTrade, traderii pot utiliza efectul de levier pentru a tranzacționa schimbări de preț în acțiuni. Prețurile acțiunilor sunt afectate de o varietate de factori, cum ar fi macroeconomia, perspectivele industriei și operațiunile companiei, ceea ce le face foarte volatile și există întotdeauna oportunități de tranzacționare a investițiilor.",
        },
        coins: {
            title: "Sincronizează dosarul",
            p: "Criptomonedele sunt o monedă digitală creată prin cod. Acestea operează autonom în afara sistemelor bancare și guvernamentale tradiționale. După cum sugerează și numele, criptomonedele folosesc criptarea pentru a securiza tranzacțiile și pentru a asigura crearea altor unități, Bitcoin fiind originalul și de departe cea mai faimoasă criptomonedă, Bitcoin a fost creat de Satoshi Nakamoto și lansat în ianuarie 2009. Chiar și astăzi, rămâne necunoscut dacă numele Satoshi Nakamoto se referă la o persoană sau un grup de oameni. Bitcoin este considerat a fi prima criptomonedă descentralizată. Ca toate criptomonedele, acesta este controlat printr-o bază de date de tranzacții blockchain care servește ca registru public distribuit. Până în prezent, peste 1.000 de criptomonede sunt tranzacționate online.",
        },
    },
    trade: {
        data_sources: "Surse de date",
        tip: " Informațiile de mai sus sunt doar cu titlu de referință. StratTrade nu garantează acuratețea, actualitatea sau completitatea informațiilor și nu trebuie să vă bazați pe informațiile furnizate. Aceste informații nu includ înregistrările noastre de prețuri sau orice ofertă sau solicitare de tranzacționare a oricăror instrumente financiare. StratTrade nu este o companie de consultanță financiară și oferă doar servicii de natură executorie. Recomandăm cititorilor să solicite propriul lor sfat de investiții. Vă rugăm să consultați disclaimer-ul nostru complet.",
        tip2: "Actualizat în fiecare vineri la 15:00 (GMT)",
        tip3: "Perspectivele de tranzacționare reprezintă un instrument pentru previziunea sentimentului de tranzacționare a pieței, reflectând opiniile experților din industrie pe termen scurt și mediu și prognozele tendințelor pentru fiecare produs fierbinte.",
        analysis: {
            tit: "Strategii de tranzacționare",
            all: "Full",
            day: "În câteva zile",
            tit1: "Strategii de tranzacționare",
            tit2: "Revizuire tehnică",
        },
        calendar: {
            tit: "Calendarul financiar",
            prev_week: "Săptămâna trecută",
            next_week: "Săptămâna viitoare",
            place_date: "Data căutării",
            select: "Opțiuni",
            select1: "Ţara noastră",
            select2: "Semnificație",
            start_time: "Ora de pornire",
            end_time: "Timp de finalizare",
            search: "Uită-te după sth..",
        },
        contract: {
            tit: "Specificațiile contractului",
            type1: "Full",
            type2: "Forex",
            type3: "Mărfuri",
            type4: "Indici",
            type5: "Stocul SUA",
            type6: "Acțiuni australiene",
            tit1: "Schimb valutar major",
            tit2: "Schimb valutar minor",
            tit3: "Mărfuri",
            tit4: "Indicii principali",
            tit5: "Indicele minor",
            tit6: "Stocul SUA",
            tit7: "Acțiuni australiene",
            tit8: "Norme generale",
            p1: "Raport de planitate forțată",
            p2: "Valabilitatea ordinului în așteptare",
            p3: "Fără limite",
            p4: "Timpul de decontare a taxelor peste noapte",
            p5: "decontare zilnică; Vara: 05:00, Iarna: 06:00",
            p6: "Informații privind efectul de levier, ratele overnight etc.",
            p7: "A se vedea platformele de tranzacționare",
            p8: "Cele de mai sus se aplică tuturor soiurilor comerciale",
            tit9: "Calendar săptămânal de tranzacționare",
            p9: "Închis",
            p10: "Orele de tranzacționare de mai sus nu iau în considerare impactul vacanțelor sau ajustărilor speciale ale pieței.",
            p11: "Recomandări specifice privind timpul de tranzacționare",
            p12: "Platformă de autentificare",
            p13: "Verificați profilurile soiurilor relevante.",
        },
        forecast: {
            tit: "Perspective de tranzacționare",
            p1: "Valoarea medie",
            p2: "Bullish",
            p3: "Bearish",
            p4: "lateral",
            p5: "Orientări",
        },
        news: {
            tit: "Știri în timp real",
        },
        platforms: {
            tit: "Platforma de tranzacționare StratTrade",
            p: "Interfață simplă și intuitivă, performanță excelentă de tranzacționare, satisface nevoile mai multor investitori. Începeți să tranzacționați cu StratTrade astăzi.",
            tit1: "Platforma de tranzacționare StratTrade convenabilă și ușor de utilizat.",
            tit2: "Tranzacționați oricând, oriunde cu StratTrade App!",
            li1: "Tranzacționați sute de piețe populare cu reglementări autoritare pentru a asigura siguranța fondurilor",
            li2: "Monitorizați tendințele pieței în timp real, vizualizați analiza tranzacționării și răsfoiți cele mai recente știri financiare.",
            li3: "Vizualizați diferite tipuri de diagrame de tranzacționare în orice moment și aplicați funcții de diagramă fără probleme",
            li4: "Experimentați platforma de tranzacționare premiată StratTrade pe dispozitivul dvs. Android sau iOS!",
            code_download: "Scanează codul de descărcat",
            tit3: "Nu este nevoie să descărcați, tranzacționați online prin browserul dvs.",
            li5: "Nu este necesară descărcarea, tranzacționați online direct din browser-ul PC-ului",
            li6: "Premii câștigătoare: Cel mai bun broker Forex în Asia 2022, Cel mai bun platformă de tranzacționare în Australia 2022, Cel mai rapid Broker Fintech Forex în creștere din lume 2022",
            li7: "Oferă indicatori tehnici puternici: MACD, KDJ, RSI, TRIX, DMA, CCI, etc.",
            li8: "Echipat cu instrumente de management al riscurilor, cum ar fi stop-loss și protecția soldului negativ pentru a atenua riscul potențial al tranzacțiilor dvs.",
            tit4: "Soluția perfectă pentru tranzacțiile desktop",
            li9: "Vizualizați cu ușurință cotațiile de piață din lista auto-selectată",
            li10: "Activați alertele pentru a primi alerte privind modificarea prețurilor și alte notificări de informații",
            li11: "Mai multe aspecte grafice vă permit să urmăriți piața din diferite dimensiuni de timp.",
            li12: "Specificațiile contractelor, sentimentul de tranzacționare și analizele de tranzacționare toate într-o singură interfață, fără a fi necesară schimbarea",
            li13: "Obțineți cele mai recente știri de tranzacționare direct în StratTrade!",
            tit5: "De ce alegeți platforma de tranzacționare StratTrade?",
            row5_p1: "Siguranţă",
            row5_p2: "0% comision",
            row5_p3: "Suport pentru mai multe platforme",
            row5_p4: "Grafice tehnice",
            row5_p5: "Acces oricând, oriunde",
            row5_p6: "Diverse instrumente de desen",
        },
        quotes: {
            tit: "Date de piață",
            p1: "Vă oferă cotații instantanee, sentimentul pieței și știri conexe despre instrumentele financiare populare ale momentului.",
            hot: "Popular",
        },
        risk: {
            tit: "Sistemul de management al riscurilor",
            p1: "Toate tranzacţiile implică riscuri. Utilizând instrumentele gratuite de management al riscurilor oferite de StratTrade, vă puteți controla eficient riscul indiferent de condițiile pieței.",
            function_tit1: "Funcția Take Profit/Stop Loss",
            function_tit2: "Funcția de oprire a traseului",
            function_p1: "Blocarea profiturilor",
            function_p2: "Limitarea pierderilor",
            function_p3: "Maximizează blocarea profitului",
            function_p4: "Nu este nevoie să monitorizați automat pozițiile",
            function_p5: "Ajustarea prețului de închidere a pierderii",
            row2_tit1: "Funcția Take Profit/Stop Loss",
            row2_p1:
                " Atunci când creați o comandă nouă sau modificați o comandă existentă, vă oferim opțiunea de a seta 'Take Profit' și 'Stop Loss'. Odată stabilită, ordinul va fi închis în mod normal la prețul țintă pe care l-ați stabilit, ajutându-vă să blocați profiturile atunci când atingeți obiectivul, sau să minimizați pierderile în cazul în care piața se mișcă nefavorabil. Vă rugăm să rețineți că orice comandă poate fi scurtmetrată din cauza volatilității pieței, caz în care sistemul nu va executa ordinul la prețul implicit, ci va închide poziția la următorul preț cel mai favorabil din prețul țintă.",
            row2_tit2: "Exemplu ",
            row2_p2:
                "Preţul curent de EUR/USD este 1.13816/1.13837 (Vânzare/Cumpărare). Deschizi o comandă de cumpărare pentru 1 lot (1 lot = 100.000 EUR) la 1.13837 și setezi un Stop Loss la 1.13806.",
            row2_p3:
                "În general, atunci când prețul scade la 1.13806, ordinul de stop loss va fi declanșat și poziția va fi închisă la 1.13806 pentru o pierdere combinată de 31 $..",
            row2_p4:
                "Cu toate acestea, atunci când există o schimbare bruscă în mediul de piață și prețul alunecă direct de la 1.13837 la 1.13795, sărind direct ținta Stop Loss, piața sare și sistemul nu va putea închide poziția la 1.13806, dar va fi executat la următorul preț cel mai favorabil, adică la 1.13795, iar pierderea finală va fi de 42 USD.",
            row3_tit1: "Funcția de oprire a traseului",
            row3_tit2: "Exemplu",
            row3_p1:
                "Trailing stop-urile (cunoscute și sub numele de trailing stop-uri) sunt puternice, deoarece se schimbă automat în funcție de profiturile dvs., permițându-vă să maximizați profiturile sau să minimizați pierderile fără a fi nevoie să vă monitorizați constant pozițiile. Atunci când creați o comandă nouă, pur și simplu setați un stop final, iar atunci când prețul se mișcă în favoarea dvs., comanda dvs. stop este actualizată automat cu cel mai recent preț. Dimpotrivă, atunci când prețul se mișcă împotriva ta, ordinul stop loss va fi activat și poziția va fi închisă la distanța pe care ai stabilit-o pentru pierdere. Vă rugăm să rețineți că orice comandă poate fi scurtmetrată din cauza volatilității pieței, caz în care sistemul nu va putea executa ordinul la prețul implicit, dar va închide poziția pentru dvs. la următorul preț favorabil din prețul țintă.",
            row3_p2:
                "Preţul curent de EUR/USD este 1.13816/1.13837 (vânzare/cumpărare). Ați stabilit o comandă de cumpărare pe 1 lot la 1.13837 cu o pierdere ultimă de 100 pips (0.00100).",
            row3_p3:
                "Când prețul produsului este de 1.13816, Stop Loss este de 1.13716. Dacă prețul de vânzare al produsului se deplasează până la 1.13845, Stop Loss va fi actualizat în funcție de distanța pe care ați setat-o, iar Stop Loss actualizat va fi 1.13745.",
            row3_p4:
                " Dimpotrivă, atunci când prețul produsului scade de la 1.13845 la 1.13745, stopul de trailing va fi declanșat și poziția va fi închisă la 1.13745.",
            tip: "Punct: De obicei, cea mai mică unitate de mișcare a prețului unui instrument financiar este numită punct. Pe platforma StratTrade, se referă la ultima cifră sau zecimală a prețului unui instrument.",
        },
        sentiment: {
            tit: "Indicele emoţional",
            type1: "Full",
            type2: "Forex",
            type3: "Mărfuri",
            type4: "Indici",
            long: "Pe termen lung (finanțe)",
            short: "Scurt",
        },
    },
    layout: {
        aside: {
            nav1: "Afaceri",
            nav2: "Piață",
            nav3: "Știri",
            nav4: "Colegiul",
            nav5: "My",
            newsDialog: {
                title: "Centrul de știri",
                type1: "Notificarea sistemului",
                type2: "Buletin",
                allRead: "Marcează totul ca citit",
            },
            settingDialog: {
                title: "Configurare",
                nav1: "General",
                nav2: "Afișează preferințele",
                nav3: "Informații sistem",
                logout: "Deconectați-vă",
                setting1: "Multilingvismul",
                setting2: "Afaceri",
                setting2_tip:
                    "Adăugați automat soiuri auto-selectate la pozițiile deschise",
                setting3: "trimite înapoi informații",
                setting3_tip: "Recomandări funcționale",
                setting4: "Verificarea numelui real",
                setting4_tip: "Verificarea numelui real",
                setting5: "Mod temă",
                setting5_label1: "Obscur",
                setting5_label2: "Culoare strălucitoare",
                setting6: "Culoare în creștere sau în scădere",
                setting6_label1: "Lit. creşteri verzi şi căderi roşii",
                setting6_label2: "Lit. roşu se ridică, verde cade",
                setting7: "Configurare diagramă",
                setting7_label1: "Ediție standard",
                setting7_label2: "TradingView Edition",
                setting8: "Declarații și acorduri",
                setting8_label1: "Politica de confidențialitate",
                setting8_label2: "Declarația de informare a produsului",
                setting8_label3: "Declarația de informare a riscurilor",
                setting8_label4: "Contract cu clienții",
                feedback_title: "Recomandări funcționale",
            },
        },
        footer: {
            hot: "Soiuri populare",
            sort: "Simbolul",
            code: "Codificare",
            buy_price: "Prețul de cumpărare",
            sell_price: "Prețul vânzătorului",
            chg: "Creșterea sau scăderea prețului",
        },
        header: {
            place_search: "Căutarea soiurilor comerciale",
            balance: "Disponibil",
            balance_tip:
                "Disponibil: suma de bani din cont care poate fi folosită pentru a deschide noi poziții",
            profit_loss: "P/L",
            asset_view: "Prezentare generală a activelor",
            netValue: "Valoarea netă",
            netValue_tip:
                "Valoarea netă: valoarea contului curent, inclusiv profitul și pierderea tuturor pozițiilor",
            marginLevel: "Nivelul marjei",
            margin: "Marjă (în tranzacționarea cu instrumente derivate)",
            margin_tip: "Marjă: suma necesară pentru a deschide și menține o poziție",
            maintainsMargin: "Marja de menținere",
            maintainsMargin_tip:
                "Marjă de întreținere: valoarea minimă a marjei care trebuie menținută în contul dvs. în timp ce dețineți toate pozițiile dvs..",
        },
    },
    table: {
        label_name: "Denumire",
        label_buyPrice: "Cumpără",
        label_sellPrice: "Vinde",
        label_variableValue: "Modificare",
        trade: "Afaceri",
        label_code: "Simbolul",
        label_title: "Denumire",
        label_start: "Semnificație",
        label_country: "Ţara noastră",
        label_event: "Eveniment",
        label_previous: "Valoarea anterioară",
        label_predictive: "Valoarea proiectată",
        label_announced: "Valoarea publicată",
        label_stop_loss_min: "Distanța minimă pentru ordinele de stop loss",
        label_difference: "Spreads dinamice",
        label_contract_size: "Dimensiunea contractului",
        label_max_hand: "Dimensiunea maximă a lotului pentru o singură tranzacție",
        label_min_hand: "Dimensiunea minimă a lotului",
        label_trade_week: "Calendar săptămânal de tranzacționare",
        label_week: "1 săptămână",
        label_month: "1 ianuarie",
        label_quarter: "1 sfert",
        label_dailyTrend: "Tendința unei singure zile",
        label_tradeId: "ID tranzacție",
        label_breed: "Simbolul",
        label_tradeType: "Tipul tranzacției",
        label_currency: "Bani",
        label_amount: "Bani",
        label_balance: "Solduri",
        label_type: "Tipologie",
        label_time: "Calendar",
        label_orderNumber: "ComandăNum",
        label_pointer_number: "Handful (of)",
        label_price_money: "Deschidere",
        label_stop_win_price: "Ia profituri",
        label_stop_lose_price: "Stop-loss",
        label_openTime: "OpenTime",
        label_profit_loss: "P/L",
        label_sell_buy: "Preț de cerere/cumpărare",
        label_chg: "Creșterea sau scăderea prețului",
        label_sell_price: "Prețul vânzătorului",
        label_buy_price: "Prețul de cumpărare",
        label_condition: "Precondiție",
    },
    form: {
        label_feedback: "Aspecte și recomandări",
        place_feedback: "Vă rugăm să introduceți întrebarea sau sugestia dumneavoastră",
        label_img: "Fotografie",
        label_img_tip: "Opțional, oferă o captură de ecran a întrebării",
        feedback_tip: "Dacă aveți o întrebare urgentă, vă rugăm să contactați",
        online: "Serviciul pentru clienți online",
        label_name: "Nume",
        place_name: "Vă rugăm să introduceți numele dumneavoastră",
        label_email: "Adresa de e-mail",
        place_email: "Adresa de e-mail",
        message_email: "Vă rugăm să introduceți adresa de e-mail",
        label_nationality: "Cetățenia",
        place_nationality: "Vă rugăm să introduceți naționalitatea",
        place_questionType: "Tipul problemei",
        message_questionType: "Vă rugăm să selectați tipul",
        questionType0: "Platformă de tranzacționare",
        questionType1: "Produse și taxe",
        questionType2: "Abonați-vă la analiza zilnică a pieței",
        questionType3: "Serviciul clienților",
        questionType4: "Restul",
        place_question: "Întrebarea ta",
        message_question: "Vă rugăm să introduceți o întrebare",
        submit_question: "Prezentarea întrebărilor",
        label_phone: "Număr de telefon mobil",
        place_phone: "Vă rugăm să introduceți numărul de telefon mobil",
        message_phone: "Vă rugăm să introduceți numărul de telefon mobil",
        label_password: "Criptografică",
        place_password: "Vă rugăm să introduceți parola",
        message_password: "Vă rugăm să introduceți parola",
        label_confirmPassword: "Confirmă parola",
        place_confirmPassword: "Vă rugăm să introduceți parola din nou",
        message_confirmPassword: "Vă rugăm să introduceți parola din nou",
        label_captcha: "CAPTCHA, un tip de test provocare-răspuns (calcul)",
        place_captcha: "Vă rugăm să introduceți codul de verificare",
        message_captcha: "Vă rugăm să introduceți codul de verificare",
        get_captcha: "Codul de verificare",
        label_inviteId: "Codul invitației",
        place_inviteId: "Codul invitației (opțional)",
        to: "Pentru",
        start_time: "Ora de pornire",
        end_time: "Ora sfârșitului",
        label_cardNumber: "Număr ID",
        place_cardNumber: "Vă rugăm să introduceți numărul de identificare",
        message_cardNumber: "Vă rugăm să introduceți numărul de identificare",
        label_cardMain: "Fața documentului",
        message_cardMain: "Vă rugăm să introduceți partea din față a documentului",
        label_cardBack: "Partea inversă a unui document",
        message_cardBack: "Introduceţi partea din spate a documentului",
        confirm_modify: "Confirmarea modificărilor",
        label_realName: "Prenumele",
        place_realName: "Vă rugăm să introduceți prenumele",
        message_realName: "Vă rugăm să introduceți prenumele",
        label_firstName: "Numele de familie",
        place_firstName: "Vă rugăm să introduceți numele de familie",
        message_firstName: "Vă rugăm să introduceți numele de familie",
        label_lastName: "Nume mijlociu",
        place_lastName: "Optional",
        message_lastName: "Introduceți numele mijlociu",
        label_birthday: "Naștere",
        place_birthday: "Vă rugăm să introduceți data nașterii",
        message_birthday: "Vă rugăm să introduceți data nașterii",
        place_nowPsd: "Vă rugăm să introduceți parola curentă",
        message_nowPsd: "Vă rugăm să introduceți parola curentă",
        place_newPsd: "Introduceți o parolă nouă",
        message_newPsd: "Introduceți o parolă nouă",
        place_crmPsd: "Vă rugăm să confirmați noua parolă",
        message_crmPsd: "Vă rugăm să confirmați noua parolă",
        label_breed: "Simbolul",
        place_breed: "Introduceți o specie",
        label_phase: "Fii",
        label_buyPrice: "Prețul de cumpărare",
        label_sellPrice: "Prețul vânzătorului",
        label_do: "Din cauza",
        label_height: "Mai mare decât",
        label_low: "Să fie mai mică decât",
        label_equal: "Sau egal cu",
        labelWalletName: "Numele portofelului",
        messageWalletName: "Vă rugăm să introduceți numele portofelului",
        placeWalletName: "Vă rugăm să introduceți numele portofelului",
        labelWalletAddress: "Adresa portofelului",
        messageWalletAddress: "Vă rugăm să introduceți adresa portofelului",
        placeWalletAddress: "Vă rugăm să introduceți adresa portofelului",
        labelAmount: "Bani",
        messageAmount: "Vă rugăm să introduceți suma",
        placeAmount: "Vă rugăm să introduceți suma",
        placeTimeLimit: "Introduceți un termen limită",
        messageTimeLimit: "Introduceți un termen limită",
    },
    pay: {
        title: "Acces facil la fonduri",
        p1: "O varietate de canale de plată utilizate în mod obișnuit, suport pentru depunere și retragere rapidă",
        p2: "Este posibil ca unele metode de plată să nu fie disponibile în țara dumneavoastră.",
        p3: "Bine ați venit la experiența serviciului StratTrade!",
        btn: "Comerț acum ",
    },
    header: {
        tip1: "Tranzacționarea cu efect de levier este un produs financiar complex cu risc ridicat de pierdere rapidă.",
        btn1: "platformă de tranzacționare",
        btn2: "Deconectați-vă",
        btn3: "Autentifică-te",
        BigUint64Array: "Comerț acum",
    },
    footer: {
        help: "Contactează-ne",
        tip1: "Urmărește comunitatea noastră",
        tip2: "Vă rugăm să rețineți că StratTrade nu a înființat un grup oficial Telegram, iar orice grup Telegram format în numele StratTrade este suspectat de falsificare.",
        tip3: "AVERTISMENT DE RISC: Tranzacționarea poate duce la pierderea tuturor fondurilor dvs. Tranzacționarea de instrumente derivate OTC nu este potrivită pentru toată lumea. Vă rugăm să citiți cu atenție documentele noastre juridice înainte de a utiliza serviciile noastre și să vă asigurați că înțelegeți pe deplin riscurile implicate înainte de tranzacționare. Nu dețineți sau dețineți efectiv niciunul dintre activele subiacente.",
        tip4: "StratTrade nu oferă sfaturi, recomandări sau opinii privind cumpărarea, deținerea sau vânzarea. Toate produsele pe care le oferim sunt derivate extrabursiere bazate pe active globale. Toate serviciile oferite de StratTrade se bazează numai pe executarea comenzilor comerciale.",
        tip5: "StratTrade este un brand de afaceri împărtășit de mai multe companii și operat prin următoarele companii:",
        tip6: "StratTrade International Ltd este emitentul produselor financiare descrise sau disponibile pentru utilizare pe acest site StratTrade International Ltd este autorizată și reglementată de Comisia Serviciilor Financiare (FSC) din Mauritius sub numărul de licență GB20025791, cu adresa sa înregistrată la 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
        tip7: "StratTrade Global Pty Ltd este înregistrată sub ABN 90 149 011 361, licența australiană de servicii financiare (AFSL) numărul 398528.",
        tip8: "StratTrade Holding Ltd este autorizată și reglementată de Autoritatea Monetară a Insulelor Cayman (CIMA) sub numărul de licență SIB 1612446.",
        tip9: "Informațiile de pe acest site nu sunt destinate rezidenților din Statele Unite ale Americii, Canada, Japonia sau Noua Zeelandă și nici nu sunt destinate utilizării de către nimeni din orice țară sau jurisdicție în care publicarea sau utilizarea lor ar fi contrară legislației sau reglementărilor locale. Vă rugăm să rețineți că limba engleză este limba principală a serviciilor noastre și este limba obligatorie din punct de vedere juridic a tuturor documentelor noastre contractuale. Traducerile în alte limbi sunt doar cu titlu de referință, iar în cazul oricărei discrepanțe între versiunile în limba engleză și cea chineză, versiunea în limba engleză prevalează.",
        tip10:
            "criptare securizată a comunicaţiilor SSL. © StratTrade Toate drepturile rezervate.",
        link1: "Politica de confidențialitate",
        link2: "Declarația de informare a produsului",
        link3: "Procesul de reclamații",
        link4: "Declarația de informare a riscurilor",
        link5: "Contract cu clienții",
        toTop: "Sticky (dintr-un subiect de internet forum etc.)",
    },
    nav: {
        tit1: "Piețe",
        tit2: "Tranzacționare",
        tit3: "Învață",
        tit4: "Despre noi",
        nav1: "Forex",
        nav2: "Indici",
        nav3: "Mărfuri",
        nav4: "Acțiuni",
        nav5: "Platforme comerciale",
        nav6: "Strategii de tranzacționare",
        nav7: "Perspective de tranzacționare",
        nav8: "Calendarul financiar",
        nav9: "Știri în timp real",
        nav10: "Date de piață",
        nav11: "Indicele emoţional",
        nav12: "Sistemul de management al riscurilor",
        nav13: "Specificațiile contractului",
        nav14: "Baze de tranzacționare",
        nav15: "Informații privind investițiile",
        nav16: "Academia",
        nav17: "Despre StratTrade",
        nav18: "Premiile noastre",
        nav19: "Centru media",
        nav20: "Securitatea fondurilor clienților",
        nav21: "Taxe și taxe",
        nav22: "Afiliați",
        nav23: "Contactează-ne",
        nav24: "Centru de ajutor",
        nav25: "Centru de ajutor",
    },

    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "ARMENIA",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgia",
        bosnia_and_Herzegovina: "Bosnia şi Herţegovina",
        brazil: "Brazilia",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodgia",
        canada: "Canada",
        cameroon: "Camerun",
        chile: "Chile",
        colombia: "Columbia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cipru",
        czech_Republic: "Republica Cehă",
        denmark: "Danemarca",
        dominican_Republic: "Republica Dominicană",
        egypt: "Egipt",
        estonia: "Estonia",
        ethiopia: "Etiopia",
        finland: "Finlanda",
        france: "Franţa",
        georgia: "Georgia",
        germany: "Germania",
        ghana: "Ghana",
        greece: "Grecia",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Ungaria",
        iceland: "Islanda",
        ireland: "Irlanda",
        italy: "Italia",
        india: "India",
        indonesia: "Indonezia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japonia",
        kazakstan: "Kazahstan",
        kenya: "Kenya",
        korea: "Coreea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kârgâzstan",
        laos: "Laos",
        latvia: "Letonia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao, China",
        macedonia: "Macedonia",
        malaysia: "Malaezia",
        malta: "Malta",
        mexico: "Mexic",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Muntenegru",
        morocco: "Maroc",
        myanmar: "Myanmar",
        netherlands: " Olanda",
        new_Zealand: " Noua_Zeelandă",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norvegiană",
        oman: "Oman",
        palestine: "Palestina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Filipine",
        poland: "Poland",
        portugal: "Portugalia",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "România",
        russia: "Rusia",
        republic_of_Trinidad_and_Tobago: "Republica Trinidad și Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Arabia Saudită",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovacia",
        slovenia: "Slovenia",
        south_Africa: "Africa de Sud",
        spain: "Spania",
        sri_Lanka: "Sri Lanka",
        sweden: "Suedia",
        switzerland: "Elveţia",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tadjikistanul",
        tanzania: "Tanzania",
        thailand: "Thailanda",
        turkey: "Turcia",
        turkmenistan: "Turkmenistan",
        ukraine: "Ucraina",
        united_Arab_Emirates: "Emiratele Arabe Unite",
        united_Kingdom: "Regatul Unit",
        united_States: "Statele Unite",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Insulele Virgine Britanice",
        burkina_Faso: "burkina faso",
        burundi: "Burundi",
        cape_Verde: "Capul Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Republica Centrafricană",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: "Republica_Congo",
        democratic_Republic_of_the_Congo: "Republica Democratică Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guineea Ecuatorială",
        eritrea: "Eritreea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Groenlanda",
        guatemala: "Guatemala",
        guinea: "Guineea",
        haiti: "Haiti",
        isle_of_Man: "Isle of Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaica",
        jordan: "Iordania",
        lebanon: "Liban",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libia",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldive",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambic",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republica Niger",
        north_Korea: "Coreea de Nord",
        reunion: "Reunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "sultan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Insulele Virgine ale Statelor Unite",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Iugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
    },
};
